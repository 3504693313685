define('effectus-app/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Route } = Ember;

  // Ensure the application route exists for ember-simple-auth's `setup-session-restoration` initializer
  exports.default = Route.extend();
});