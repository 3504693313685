define("effectus-app/routes/patienttracking", ["exports", "effectus-app/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var transitionConfirm = true;

  exports.default = Ember.Route.extend({
    session: Ember.inject.service("session"),
    cognito: Ember.inject.service("cognito"),
    cognitoUser: Ember.computed.readOnly("cognito.user"),
    progr: Ember.inject.service("current-program"),
    exportCsvService: Ember.inject.service("export-csv"),

    beforeModel() {
      //modelHookRun = false;
      if (this.get("progr").getPhysician() == null) {
        this.transitionTo("patienttrackingselect");
      }
      if (this.get("progr").getProgram() == null) {
        this.transitionTo("index");
      }
      if (this.get("progr").getRole() == "recruiter") {
        this.transitionTo("physicians");
      }
      if (this.get("progr").getRole() == "trainer" || this.get("progr").getRole() == "doctor") {
        this.transitionTo("patientlog");
      }
      if (this.get("progr").getRole() == null) {
        this.transitionTo("index");
      }
    },

    model() {
      //modelHookRun = true;

      let userHasExportAllPermission = this.get("progr").get("personIsAdmin"); // exporting all HCPs is only available to admins
      return Ember.RSVP.hash({
        patienttracking: this.store.query("patienttracking", {
          study_id: this.get("progr").getProgram(),
          phys_id: this.get("progr").getPhysician()
        }),
        newpatient: this.store.createRecord("patienttracking", {
          study_id: this.get("progr").getProgram(),
          site_id: this.get("progr").getSite(),
          physician_id: this.get("progr").getPhysician(),
          audit_modifiedby: this.get("progr").getEmail()
        }),
        question: this.store.query("patienttrackingquestion", {
          study_id: this.get("progr").getProgram()
        }),
        userHasExportAllPermission: userHasExportAllPermission,
        patientTrackingForAllHcps: userHasExportAllPermission && this.store.query("patienttracking", {
          study_id: this.get("progr").getProgram()
        }), // used to export data for all HCPs
        patientTrackingForReify: userHasExportAllPermission && this.store.query("patienttracking", {
          study_id: this.get("progr").getProgram()
        }).then(results => results.filter(patienttracking => {
          return patienttracking.get("sent_to_reify") === false && patienttracking.get("enrollment_status") === "In Progress" && patienttracking.get("reify_site_id") !== null;
        })),
        program: this.store.query("program", {
          study_id: this.get("progr").getProgram()
        }),
        contact: this.store.query("contact", {
          site_id: this.get("progr").getSite()
        }),
        reasondisqualified: this.store.findAll("reasondisqualified"),
        state: this.store.findAll("state"),
        country: this.store.findAll("country")
      });
    },

    setupController(controller, model) {
      controller.set("patienttracking", model.patienttracking);
      controller.set("newpatient", model.newpatient);
      controller.set("question", model.question);
      controller.set("userHasExportAllPermission", model.userHasExportAllPermission);
      controller.set("patientTrackingForAllHcps", model.patientTrackingForAllHcps);
      controller.set("patientTrackingForReify", model.patientTrackingForReify);
      controller.set("contact", model.contact);
      controller.set("program", model.program);
      controller.set("reasondisqualified", model.reasondisqualified);
      controller.set("state", model.state);
      controller.set("country", model.country);

      //this logic is to handle users coming to the site from an email link
      //the email link takes the user to newly added patient tracking record
      if (this.get("progr").getPatient()) {
        let patient_id = parseInt(this.get("progr").getPatient());
        this.controller.set("isEditingPatient", true);
        this.controller.set("editModePatientID", patient_id);
        let patient = controller.get("patienttracking").findBy("patient_tracking_id", patient_id);
        patient.set("editMode", true);
        patient.set("highlightPatient", "cell-selected");
        let physician_name = patient.get("physician_fname") + " " + patient.get("physician_lname");
        this.get("progr").setName(physician_name); //set the physician name for display
        //reset redirect values
        this.get("progr").setPatient(null);
        this.get("progr").set("pt_redirect", false);
      }
    },
    actions: {
      willTransition: function (transition) {
        if (transitionConfirm && (this.controller.get("isEditingPatient") || this.controller.get("isAddingNewPatient"))) {
          if (transition.targetName !== "patienttracking") {
            if (!confirm("You have unsaved changes. Are you sure you want to abandon changes?")) {
              transition.abort();
            } else {
              if (this.controller.get("isEditingPatient")) {
                let patient = this.controller.get("patienttracking").findBy("id", this.controller.get("editModePatientID"));
                patient.rollbackAttributes();
                resetEditMode(patient, this.controller);
              }
              if (this.controller.get("isAddingNewPatient")) {
                let patient = this.controller.get("newpatient");
                patient.rollbackAttributes();
                resetAddMode(this.controller);
              }
              this.refresh();
            }
          }
        }
        transitionConfirm = true;
      },
      addPatient(patienttracking) {
        this.controller.set("isProcessing", true);
        let program = this.controller.get("program");
        let study = program.findBy("study_id", parseInt(patienttracking.get("study_id"))).get("name");
        patienttracking.set("study", study);
        patienttracking.save().then(() => {
          resetAddMode(this.controller);
          this.refresh();
        }).catch(res => {
          this.controller.set("isProcessing", false);
          let errorMessage = res.errors[0].detail;
          this.controller.set("errorMessage", errorMessage);
          transitionConfirm = false;
        });
      },
      updatePatient(patienttracking) {
        this.controller.set("isProcessing", true);
        patienttracking.save().then(() => {
          resetEditMode(patienttracking, this.controller);
          this.refresh();
        }).catch(res => {
          this.controller.set("isProcessing", false);
          let errorMessage = res.errors[0].detail;
          this.controller.set("errorMessage", errorMessage);
          transitionConfirm = false;
        });
      },
      deletePatient(patienttracking) {
        let confirmation_txt = "Are you sure you want to delete this patient?";
        let confirmation = confirm(confirmation_txt);
        if (confirmation) {
          patienttracking.destroyRecord().then(() => {
            resetEditMode(patienttracking, this.controller);
            transitionConfirm = false; //do not process willTransition
            this.refresh();
          });
        }
      },
      exportCsv() {
        let config = {
          filename: "patient-tracking.csv",
          columns: [{ heading: "HCP First Name", key: "physician_fname" }, { heading: "HCP Last Name", key: "physician_lname" }, { heading: "Patient Name", key: "patient_name" }, { heading: "Parent/Guardian Name", key: "guardian_name" }, { heading: "Patient ID #", key: "patient_deid_number" }, { heading: "Study Site #", key: "site_code" }, { heading: "Patient Status", key: "enrollment_status" }, {
            heading: "Disqualification/Failed Reason",
            key: "failed_disqualified_reason"
          }, { heading: "Cell Phone Number", key: "phone_preferred" }, { heading: "Alternate Phone Number", key: "phone_alternate" }, { heading: "Email Address", key: "email_preferred" }, { heading: "Address", key: "address" }, { heading: "Address 2", key: "address2" }, { heading: "City", key: "city" }, { heading: "State", key: "state" }, { heading: "Country", key: "country" }, { heading: "Zip", key: "zip" }, {
            heading: "Date Pt Signed Contact Authorization",
            key: "date_signed_contactauth"
          }, {
            heading: "Date Effectus Received Contact Authorization",
            key: "date_received_contactauth"
          }, {
            heading: "Screening Appt Was Scheduled By Treating HCP",
            key: "hcp_scheduled_appt"
          }]
        };

        // Add headers for dynamic questions
        this.get("controller").get("question").forEach(function (question) {
          // let key = question.get("question_key");

          if (!question.get("isHeader")) {
            let heading = question.get("section") + question.get("question_value").replace(/:$/, ""); //prepend section & strip trailing semicolon
            let questionColumn = {
              heading: heading,
              key: question.get("question_key")
            };
            config.columns.push(questionColumn);
          }
        });
        config.columns.push({ heading: "General Notes", key: "general_notes" });
        config.columns.push({ heading: "Date Modified", key: "audit_modified" });

        let patientTrackings = this.get("controller").get("patientTrackingForAllHcps");
        if (patientTrackings) {
          let questions = this.get("controller").get("question");
          let data = patientTrackings.map(patientTracking => buildExportFromPatientTracking(patientTracking, config, questions, this.get("controller")));
          this.get("exportCsvService").export(data, config);
        }
      },
      exportReifyCsv() {
        const today = new Date();
        const day = today.toLocaleString("default", { day: "2-digit" });
        const month = today.toLocaleString("default", { month: "short" });
        const year = today.toLocaleString("default", { year: "numeric" });
        const date = day + "_" + month + "_" + year;
        let config = {
          filename: "trial_export_" + date + ".csv",
          columns: [{ heading: "partner_referral_id", key: "partner_referral_id" }, { heading: "reify_site_id", key: "reify_site_id" }, { heading: "reify_trial_id", key: "reify_trial_id" }, { heading: "sponsor_site_id", key: "site_code" }, { heading: "first_name", key: "patient_first_name" }, { heading: "last_name", key: "patient_last_name" }, { heading: "phone", key: "phone_preferred" }, { heading: "email", key: "email_preferred" }, { heading: "address", key: "address" }, { heading: "consent_verified", key: "consent_verified" }, { heading: "consent_text", key: "consent_text" }, { heading: "question_1", key: "question_1" }, { heading: "answer_1", key: "answer_1" }]
        };

        this.refresh();

        let patientTrackingForReify = this.get("controller").get("patientTrackingForReify");

        if (patientTrackingForReify && patientTrackingForReify.length > 0) {
          let questions = this.get("controller").get("question");
          let data = patientTrackingForReify.map(patientTrackingReify => buildReifyExportFromPatientTracking(patientTrackingReify, config, questions, this.get("controller")));

          //let patienttracking = this.get("controller").get("patienttracking");
          patientTrackingForReify.forEach(function (reifyPatient) {
            //let patient = patienttracking.findBy("id", reifyPatient.get("id"));
            reifyPatient.set("sent_to_reify", 1);
            reifyPatient.set("date_sent_to_reify", today);
            reifyPatient.save();
          });

          this.get("exportCsvService").export(data, config);
        } else {
          alert("Reify Export\nNo patient(s) to export. If new patient(s) have been added, please refresh the page and try exporting again.");
        }
      } } //end action section
  });


  function resetEditMode(patient, controller) {
    if (patient) {
      patient.set("highlightPatient", "");
      patient.set("editMode", "");
    }
    if (controller) {
      controller.set("isProcessing", false);
      controller.set("isEditingPatient", false);
      controller.set("errorMessage", "");
      controller.set("currentQuestionKey", "");
      controller.set("editModePatientID", 0); //reset patient being edited
    }
  }

  function resetAddMode(controller) {
    if (controller) {
      controller.set("isAddingNewPatient", false); //patient added, exit adding mode
      controller.set("errorMessage", "");
      controller.set("currentQuestionKey", "");
      controller.set("isProcessing", false);
    }
  }

  /**
   * Extract data from patientTracking based on export config.
   * @param patientTracking - model object
   * @param config - export configuration
   */
  function buildExportFromPatientTracking(patientTracking, config, questions, controller) {
    let exportModel = Ember.Object.create();

    // Add any existing values from the root object (Physician Name, De-Id Number, etc.)
    for (let configColumn of config.columns) {
      if (patientTracking.get(configColumn.key)) {
        exportModel.set(configColumn.key, patientTracking.get(configColumn.key));
      }
    }

    // Completed dynamic questions in patientTracking.compQuestions are stored in the form key*||*value,value
    // The values may have leading spaces and/or comma
    // We'll reformat that into CSV-ready key-value pairs
    // Multi-Select items will be comma-separated
    questions.forEach(function (question) {
      let type = question.get("answer_type");
      let key = question.get("question_key");
      let keyedAnswerValues = question.get("arrKeyedValues");

      let patientAnswers = JSON.parse(controller.get("dataUtil").escape(patientTracking.get("patient_answers")));
      let answer = patientAnswers[key]; //get the patients answer (in key form)

      if (typeof answer === "undefined") {
        answer = "";
      }

      if (type !== "HEADER") {
        if (type === "DROPDOWN" || type === "RADIO") {
          answer = keyedAnswerValues[answer]; //the answer is a key. get value from answer key
        }
        if (type === "CHECKBOX") {
          if (answer) {
            let arrChecked = answer.split(",");
            let checkedAnswers = "";
            arrChecked.forEach(function (checkedKey) {
              if (checkedKey) checkedAnswers = checkedAnswers + keyedAnswerValues[checkedKey] + ",";
            });
            answer = checkedAnswers.replace(/,+$/, "");
          }
        }
        if (type === "DATE") {
          answer = controller.get("dataUtil").formatExportDate(answer);
        }
        if (type === "DATETIME") {
          let date = controller.get("dataUtil").formatExportDTDate(answer);
          let time = controller.get("dataUtil").formatExportDTTime(answer);
          answer = date.concat(" ", time);
        }
        exportModel.set(key, answer);
      }
    });

    return exportModel;
  }

  /**
   * Extract data from patientTracking based on export config.
   * @param patientTracking - model object
   * @param config - export configuration
   */
  function buildReifyExportFromPatientTracking(patientTrackingReify, config, questions, controller) {
    let exportReifyModel = Ember.Object.create();

    // Add any existing values from the root object (Physician Name, De-Id Number, etc.)
    for (let configColumn of config.columns) {
      if (patientTrackingReify.get(configColumn.key)) {
        exportReifyModel.set(configColumn.key, patientTrackingReify.get(configColumn.key));
      } else {
        let value = "";

        if (configColumn.key === "consent_verified") {
          value = "Yes";
        }
        if (configColumn.key === "consent_text") {
          value = _environment.default.reify.consent_text;
        }
        if (configColumn.key === "question_1") {
          value = "";
        }
        if (configColumn.key === "answer_1") {
          value = "";
        }
        /* do not include comm notes for now
        if (configColumn.key === "question_1") {
          value = "Notes";
        }
        if (configColumn.key === "answer_1") {
          questions.forEach(function (question) {
            let key = question.get("question_key");
            if (key === "COMMUNICATION_NOTES") {
              let patientAnswers = JSON.parse(
                controller
                  .get("dataUtil")
                  .escape(patientTrackingReify.get("patient_answers"))
              );
              if (typeof patientAnswers[key] === "undefined") {
                value = "";
              } else {
                value = patientAnswers[key];
              }
            }
          });
        }*/
        exportReifyModel.set(configColumn.key, value);
      } //if
    } // for

    return exportReifyModel;
  }
});