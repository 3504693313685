define('effectus-app/controllers/physicians/assign', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        site_id: "",
        physician_id: "",
        errorMessage: "",
        actions: {
            setSite(value) {
                this.set('site_id', value);
            },
            setHCP(value) {
                $("#hcp_avail_email").val(0);
                this.set('physician_id', value);
            },
            setHCPByEmail(value) {
                $("#hcp_avail_name").val(0);
                this.set('physician_id', value);
            }
        }
    });
});