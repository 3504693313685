define("effectus-app/components/phone-input", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    tagName: "input",
    type: "text",
    attributeBindings: ["name", "type", "value", "maxlength", "id"],
    keyDown: function (e) {
      var key = e.charCode || e.keyCode || 0;
      // allow enter, backspace, tab, delete, numbers, keypad numbers, home, end, -, (, ), x, + and space only
      //business requirement is to allow user to enter any number format
      return key === 13 || key === 8 || key === 9 || key === 46 || key === 32 || key === 88 || key === 187 || key === 107 || key === 109 || key === 189 || key === 57 || key === 48 || key >= 35 && key <= 40 || key >= 48 && key <= 57 || key >= 96 && key <= 105;
    },
    keyPress: function () {
      var inputValue = this.value || "";
      return inputValue.length < this.maxlength;
    }
  });
});