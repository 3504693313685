define('effectus-app/components/site-comp', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'select',
    classNames: ['form-control'],
    authors: null,
    book: null,

    change(event) {
      const selectedAuthorId = event.target.value;
      //const selectedAuthor = this.get('authors').find((record) => record.id === selectedAuthorId);

      this.sendAction('action', this.get('book'), selectedAuthorId);
    }
  });
});