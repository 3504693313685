define('effectus-app/authenticators/cognito', ['exports', 'ember-cognito/authenticators/cognito'], function (exports, _cognito) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _cognito.default;
    }
  });
});