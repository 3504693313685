define('effectus-app/routes/study', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    cognito: Ember.inject.service('cognito'),
    cognitoUser: Ember.computed.readOnly('cognito.user'),
    needs: ['application'],
    progr: Ember.inject.service('current-program'),
    exportCsvService: Ember.inject.service('export-csv'),

    beforeModel() {
      if (this.get('progr').getProgram() == null) {
        this.transitionTo('index');
      }
      if (this.get('progr').getRole() == 'recruiter') {
        this.transitionTo('physicians');
      }
      if (this.get('progr').getRole() == 'trainer' || this.get('progr').getRole() == 'doctor') {
        this.transitionTo('patientlog');
      }
      if (this.get('progr').getRole() == null) {
        this.transitionTo('index');
      }
    },

    model(params) {
      return Ember.RSVP.hash({
        study: this.store.findRecord('study', this.get('progr').getProgram())
      });
    },

    setupController(controller, model) {
      controller.set('study', model.study);
    },

    actions: {
      updateStudy(study) {
        study.set('audit_modifiedby', this.get('progr').getEmail());
        study.save().then(() => {
          this.controller.set('errorMessage', '');
          this.refresh();
        }).catch(res => {
          let errorMessage = res.errors[0].detail;
          this.controller.set('errorMessage', errorMessage);
        });
      }
    }
  });
});