define('effectus-app/controllers/patientlogselect', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    progr: Ember.inject.service('current-program'),

    init: function () {
      this._super();
    },

    actions: {
      doSomethingOnClose(book) {
        book.set('audit_modifiedby', this.get('progr').getEmail());
        book.save();
      },

      setSelection: function (selected) {
        this.set('selectedOption', selected);

        var split = this.get('selectedOption').split("%%%%");

        this.get('progr').setPhysician(split[0]);
        this.get('progr').setSite(split[1]);
        this.get('progr').setName(split[2]);

        this.transitionToRoute('patientlog');
      }
    }
  });
});