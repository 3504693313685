define('effectus-app/adapters/patientlogquestion', ['exports', 'effectus-app/adapters/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({

		pathForType() {
			return 'getDeIDLogQuestions';
		}
	});
});