define('effectus-app/adapters/refusal', ['exports', 'effectus-app/adapters/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({

		pathForType() {
			return 'getRefusals';
			//	return 'getRefusals?id_token=' + this.get('progr').getToken();
		}

	});
});