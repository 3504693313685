define("effectus-app/routes/enlist", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var transitionConfirm = true;
  const ROW_DELIMITER = "\n";
  const COL_DELIMITER = "\t";
  const COL_TOTAL = 40;
  const COLUMN = Object.freeze({
    FNAME: 0,
    LNAME: 1,
    DEGREE: 2,
    SPECIALTY: 3,
    PRACTICE_TYPE: 4,
    INSTITUTION: 5,
    ADDRESS: 6,
    ADDRESS2: 7,
    CITY: 8,
    STATE: 9,
    ZIP: 10,
    PREFERRED_EMAIL: 11,
    ALTERNATE_EMAIL: 12,
    PHONE1: 13,
    PHONE1_TYPE: 14,
    PHONE2: 15,
    PHONE2_TYPE: 16,
    FAX: 17,
    NPI: 18,
    OPTIN_TEXT: 19,
    SLN: 20,
    BACKGROUND_DATE: 21,
    BACKGROUND_STATUS: 22,
    OPTIN_PROGRAMS: 23,
    OPTIN_NETWORK: 24,
    PREVIOUSLY_PI: 25,
    COMPLETED_EFFECTUS_CONSENT: 26,
    COMPLETED_PI_NETWORK: 27,
    OPTOUT: 28,
    JOB1: 29,
    PROGRAM1: 30,
    JOB2: 31,
    PROGRAM2: 32,
    JOB3: 33,
    PROGRAM3: 34,
    JOB4: 35,
    PROGRAM4: 36,
    JOB5: 37,
    PROGRAM5: 38,
    NOTES: 39
  });

  exports.default = Ember.Route.extend({
    progr: Ember.inject.service("current-program"),
    exportCsvService: Ember.inject.service("export-csv"),
    beforeModel() {
      if (this.get("progr").getRole() == "recruiter") {
        this.transitionTo("physicians"); //redirect to physicians
      }
      if (this.get("progr").getRole() == "trainer" || this.get("progr").getRole() == "doctor") {
        this.transitionTo("patientlog"); //redirect to patient logs
      }
      if (this.get("progr").getRole() == null) {
        this.transitionTo("index"); //redirect to home
      }
    },
    model() {
      return Ember.RSVP.hash({
        enlist: this.store.findAll("enlist")
      });
    },
    setupController(controller, model) {
      controller.set("enlist", model.enlist);
      controller.set("enlistFiltered", model.enlist);
    },
    actions: {
      willTransition: function (transition) {
        if (transitionConfirm && transition.targetName !== "enlist") {
          //selected hcp
          if (this.controller.get("editID") > 0) {
            let hcp = this.controller.get("enlist").findBy("id", this.controller.get("editID"));
            let changes = Object.keys(hcp.changedAttributes()).length;
            if (changes > 0) {
              //prompt question
              if (!confirm("You have unsaved changes. Are you sure you want to abandon changes?")) {
                transition.abort();
              } else {
                hcp.rollbackAttributes();
                cancel(hcp, this.controller);
                this.refresh();
              }
            } else {
              hcp.rollbackAttributes();
              cancel(hcp, this.controller);
              this.refresh();
            }
          }
        }
        transitionConfirm = true; //reset
      }, //will transition
      upload: async function (event) {
        try {
          this.controller.set("statusMessage", "");
          this.controller.set("isProcessing", true);

          const reader = new FileReader();
          const file = event.target.files[0];

          if (file) {
            reader.onload = async () => {
              const file_data = reader.result.trim(); // Remove trailing line breaks
              const rows = file_data.split(ROW_DELIMITER);

              if (rows.length > 0) {
                const headers = rows[0].split(COL_DELIMITER); // Extract headers
                const validation_error = validateHeaders(headers);

                if (!validation_error) {
                  for (let i = 1; i < rows.length; i++) {
                    const row_data = rows[i].split(COL_DELIMITER);
                    const sanitized_data = sanitize(row_data);
                    this.controller.set("statusMessage", `${this.controller.get("statusMessage")}<br />Processed row ${i}: ${sanitized_data[COLUMN.PREFERRED_EMAIL]}`);

                    if (!sanitized_data[COLUMN.PREFERRED_EMAIL] || sanitized_data[COLUMN.PREFERRED_EMAIL] === "") {
                      this.controller.set("statusMessage", `${this.controller.get("statusMessage")} email required.`);
                    } else {
                      let enlist_hcp_update = this.store.peekAll("enlist").findBy("email_preferred", sanitized_data[COLUMN.PREFERRED_EMAIL].toLowerCase());

                      if (enlist_hcp_update) {
                        this.controller.set("statusMessage", `${this.controller.get("statusMessage")} updated.`);
                        enlist_hcp_update = updateRecord(enlist_hcp_update, sanitized_data);
                        enlist_hcp_update.set("audit_modifiedby", this.get("progr").getEmail());
                        try {
                          await enlist_hcp_update.save();
                        } catch (error) {
                          console.error(`Error updating record: ${error}`);
                        }
                      } else {
                        this.controller.set("statusMessage", `${this.controller.get("statusMessage")} added.`);
                        let enlist_hcp_new = this.store.createRecord("enlist");
                        enlist_hcp_new = createRecord(enlist_hcp_new, sanitized_data);
                        enlist_hcp_new.set("programs", getDelimitedPrograms(sanitized_data));
                        enlist_hcp_new.set("source", "Imported");
                        enlist_hcp_new.set("audit_modifiedby", this.get("progr").getEmail());
                        try {
                          await enlist_hcp_new.save();
                        } catch (error) {
                          console.error(`Error creating record: ${error}`);
                        }
                      }
                    }
                  }

                  this.controller.set("statusMessage", `${this.controller.get("statusMessage")}<br /><b>Processing complete.</b>`);
                  this.controller.set("isProcessing", false);
                  transitionConfirm = false; // Do not process willTransition
                  this.refresh();
                } else {
                  this.controller.set("statusMessage", `File error, please correct the issues and try again.<br/>${validation_error}`);
                  this.controller.set("isProcessing", false);
                }
              }
            };

            reader.readAsText(file); // Start reading the file
            this.controller.set("showProcessingMessage", true);
          }
        } catch (error) {
          this.controller.set("statusMessage", `${this.controller.get("statusMessage")} >> Error occurred: ${error}`);
        } //try
      }, //upload
      update(hcp) {
        this.controller.set("statusMessage", "");
        this.controller.set("isProcessing", true);
        hcp.set("audit_modifiedby", this.get("progr").getEmail());
        hcp.save().then(() => {
          transitionConfirm = false; //do not process willTransition
          this.refresh();
        }).catch(res => {
          this.controller.set("isProcessing", false);
          if (res.errors) {
            let errorMessage = res.errors[0].detail;
            this.controller.set("errorMessage", errorMessage);
          }
        });
        hcp.set("isEditingHCP", false);
        hcp.set("highlightHCP", "");
        this.controller.set("isProcessing", false);
        this.controller.set("isEditing", false);
      }, //update
      cancel(hcp) {
        hcp.rollbackAttributes();
        hcp.set("isEditingHCP", false);
        hcp.set("highlightHCP", "");
        this.controller.set("statusMessage", "");
        this.controller.set("isProcessing", false);
        this.controller.set("isEditing", false);
        this.controller.set("showProcessingMessage", false);
      }, //cancel
      edit(hcp) {
        this.controller.set("statusMessage", "");
        this.controller.set("isEditing", true);
        hcp.set("isEditingHCP", true);
        hcp.set("highlightHCP", "cell-selected");
      }, //edit
      delete(hcp) {
        this.controller.set("statusMessage", "");
        let confirmation_txt = "Are you sure you want to delete this hcp?";
        if (hcp.get("email_preferred")) {
          confirmation_txt = "Are you sure you want to delete hcp with email: " + hcp.get("email_preferred") + "?"; //using email since all other fields are optional
        }
        let confirmation = confirm(confirmation_txt);
        if (confirmation) {
          hcp.set("audit_modifiedby", this.get("progr").getEmail());
          hcp.destroyRecord().then(() => {
            transitionConfirm = false; //do not process willTransition
            this.refresh();
          });
        }
      }, //delete
      exportCsv() {
        this.controller.set("statusMessage", "Processing export...");
        this.controller.set("isProcessing", true);
        const config = {
          filename: "enlist.csv",
          columns: [{ heading: "First Name", key: "fname" }, { heading: "Last Name", key: "lname" }, {
            heading: "Preferred Email",
            key: "email_preferred"
          }, {
            heading: "Alternate Email",
            key: "email_alternate"
          }, { heading: "Degree", key: "degree" }, { heading: "Specialty", key: "specialty" }, { heading: "Practice Type", key: "practice_type" }, { heading: "Institution", key: "institution" }, { heading: "Address", key: "address" }, { heading: "Address2", key: "address2" }, { heading: "City", key: "city" }, { heading: "State", key: "state" }, { heading: "Zip", key: "zip" }, { heading: "Preferred Phone 1", key: "phone1" }, { heading: "Phone 1 Type", key: "phone1_type" }, { heading: "Preferred Phone 2", key: "phone2" }, { heading: "Phone 2 Type", key: "phone2_type" }, { heading: "FAX", key: "fax" }, { heading: "NPI", key: "npi" }, { heading: "SLN", key: "sln" }, { heading: "Opt in for Texts", key: "opt_in_text" }, {
            heading: "Opt in Future Programs/Surveys",
            key: "opt_in_programs_survey"
          }, {
            heading: "Opt in Effectus PI Preferred Network",
            key: "opt_in_effectus_network"
          }, {
            heading: "Background Check Completion Date",
            key: "background_check_date"
          }, {
            heading: "Background Check Status",
            key: "background_check_status"
          }, { heading: "Previous PI Experience", key: "pi_experience" }, {
            heading: "Completed Effectus Network Consent Form",
            key: "completed_consent_form"
          }, {
            heading: "Completed PI Preferred Network Form",
            key: "completed_network_form"
          }, { heading: "Opt Out/Unsubscribe", key: "opt_out" }, { heading: "Programs (Job #)", key: "programs" }, { heading: "Notes", key: "notes" }, { heading: "Source", key: "source" }]
        };

        var data = this.get("controller").get("enlist");
        this.get("exportCsvService").export(data, config);

        this.controller.set("statusMessage", "Export complete. Please check your downloads folder.");
        this.controller.set("isProcessing", false);
      } } //actions
  });


  function createRecord(hcp, row) {
    hcp.set("fname", row[COLUMN.FNAME]);
    hcp.set("lname", row[COLUMN.LNAME]);
    hcp.set("degree", row[COLUMN.DEGREE]);
    hcp.set("specialty", row[COLUMN.SPECIALTY]);
    hcp.set("practice_type", row[COLUMN.PRACTICE_TYPE]);
    hcp.set("institution", row[COLUMN.INSTITUTION]);
    hcp.set("address", row[COLUMN.ADDRESS]);
    hcp.set("address2", row[COLUMN.ADDRESS2]);
    hcp.set("city", row[COLUMN.CITY]);
    hcp.set("state", row[COLUMN.STATE]);
    hcp.set("zip", row[COLUMN.ZIP]);
    hcp.set("email_preferred", row[COLUMN.PREFERRED_EMAIL].toLowerCase());
    hcp.set("email_alternate", row[COLUMN.ALTERNATE_EMAIL].toLowerCase());
    hcp.set("phone1", row[COLUMN.PHONE1]);
    hcp.set("phone1_type", row[COLUMN.PHONE1_TYPE]);
    hcp.set("phone2", row[COLUMN.PHONE2]);
    hcp.set("phone2_type", row[COLUMN.PHONE2_TYPE]);
    hcp.set("fax", row[COLUMN.FAX]);
    hcp.set("npi", row[COLUMN.NPI]);
    hcp.set("sln", row[COLUMN.SLN]);
    hcp.set("opt_in_text", row[COLUMN.OPTIN_TEXT]);
    hcp.set("opt_in_programs_survey", row[COLUMN.OPTIN_PROGRAMS]);
    hcp.set("opt_in_effectus_network", row[COLUMN.OPTIN_NETWORK]);
    hcp.set("pi_experience", row[COLUMN.PREVIOUSLY_PI]);
    hcp.set("background_check_date", row[COLUMN.BACKGROUND_DATE]);
    hcp.set("background_check_status", row[COLUMN.BACKGROUND_STATUS]);
    hcp.set("completed_consent_form", row[COLUMN.COMPLETED_EFFECTUS_CONSENT]);
    hcp.set("completed_network_form", row[COLUMN.COMPLETED_PI_NETWORK]);
    hcp.set("opt_out", row[COLUMN.OPTOUT]);
    hcp.set("notes", row[COLUMN.NOTES]);
    return hcp;
  }
  function updateRecord(hcp, row) {
    if (row) {
      //if value set, override old data with new data
      //exclude email_preferred from this check since record was matched using this field
      if (row[COLUMN.FNAME]) hcp.set("fname", row[COLUMN.FNAME]);
      if (row[COLUMN.LNAME]) hcp.set("lname", row[COLUMN.LNAME]);
      if (row[COLUMN.ALTERNATE_EMAIL]) hcp.set("email_alternate", row[COLUMN.ALTERNATE_EMAIL].toLowerCase());
      if (row[COLUMN.DEGREE]) hcp.set("degree", row[COLUMN.DEGREE]);
      if (row[COLUMN.SPECIALTY]) hcp.set("specialty", row[COLUMN.SPECIALTY]);
      if (row[COLUMN.PRACTICE_TYPE]) hcp.set("practice_type", row[COLUMN.PRACTICE_TYPE]);
      if (row[COLUMN.INSTITUTION]) hcp.set("institution", row[COLUMN.INSTITUTION]);
      if (row[COLUMN.ADDRESS]) hcp.set("address", row[COLUMN.ADDRESS]);
      if (row[COLUMN.ADDRESS2]) hcp.set("address2", row[COLUMN.ADDRESS2]);
      if (row[COLUMN.CITY]) hcp.set("city", row[COLUMN.CITY]);
      if (row[COLUMN.STATE]) hcp.set("state", row[COLUMN.STATE]);
      if (row[COLUMN.ZIP]) hcp.set("zip", row[COLUMN.ZIP]);
      if (row[COLUMN.PHONE1]) hcp.set("phone1", row[COLUMN.PHONE1]);
      if (row[COLUMN.PHONE1_TYPE]) hcp.set("phone1_type", row[COLUMN.PHONE1_TYPE]);
      if (row[COLUMN.PHONE2]) hcp.set("phone2", row[COLUMN.PHONE2]);
      if (row[COLUMN.PHONE2_TYPE]) hcp.set("phone2_type", row[COLUMN.PHONE2_TYPE]);
      if (row[COLUMN.FAX]) hcp.set("fax", row[COLUMN.FAX]);
      if (row[COLUMN.NPI]) hcp.set("npi", row[COLUMN.NPI]);
      if (row[COLUMN.SLN]) hcp.set("sln", row[COLUMN.SLN]);
      if (row[COLUMN.OPTIN_TEXT]) hcp.set("opt_in_text", row[COLUMN.OPTIN_TEXT]);
      if (row[COLUMN.OPTIN_PROGRAMS]) hcp.set("opt_in_programs_survey", row[COLUMN.OPTIN_PROGRAMS]);
      if (row[COLUMN.OPTIN_NETWORK]) hcp.set("opt_in_effectus_network", row[COLUMN.OPTIN_NETWORK]);
      if (row[COLUMN.PREVIOUSLY_PI]) hcp.set("pi_experience", row[COLUMN.PREVIOUSLY_PI]);
      if (row[COLUMN.BACKGROUND_DATE]) hcp.set("background_check_date", row[COLUMN.BACKGROUND_DATE]);
      if (row[COLUMN.BACKGROUND_STATUS]) hcp.set("background_check_status", row[COLUMN.BACKGROUND_STATUS]);
      if (row[COLUMN.COMPLETED_EFFECTUS_CONSENT]) hcp.set("completed_consent_form", row[COLUMN.COMPLETED_EFFECTUS_CONSENT]);
      if (row[COLUMN.COMPLETED_PI_NETWORK]) hcp.set("completed_network_form", row[COLUMN.COMPLETED_PI_NETWORK]);
      if (row[COLUMN.OPTOUT]) hcp.set("opt_out", row[COLUMN.OPTOUT]);

      var programs = getDelimitedPrograms(row);
      if (programs) {
        //for programs append to list
        if (hcp.get("programs")) {
          hcp.set("programs", hcp.get("programs") + ", " + programs);
        } else {
          hcp.set("programs", programs);
        }
      }

      if (row[COLUMN.NOTES]) hcp.set("notes", row[COLUMN.NOTES]);
    }
    return hcp;
  }

  function getDelimitedPrograms(row_data) {
    var programs = "";
    if (row_data[COLUMN.PROGRAM1]) {
      programs = row_data[COLUMN.PROGRAM1] + " (" + row_data[COLUMN.JOB1] + ")";
    }
    if (row_data[COLUMN.PROGRAM2]) {
      if (programs) {
        programs += ", ";
      }
      programs += row_data[COLUMN.PROGRAM2] + " (" + row_data[COLUMN.JOB2] + ")";
    }
    if (row_data[COLUMN.PROGRAM3]) {
      if (programs) {
        programs += ", ";
      }
      programs += row_data[COLUMN.PROGRAM3] + " (" + row_data[COLUMN.JOB3] + ")";
    }
    if (row_data[COLUMN.PROGRAM4]) {
      if (programs) {
        programs += ", ";
      }
      programs += row_data[COLUMN.PROGRAM4] + " (" + row_data[COLUMN.JOB4] + ")";
    }
    if (row_data[COLUMN.PROGRAM5]) {
      if (programs) {
        programs += ", ";
      }
      programs += row_data[COLUMN.PROGRAM5] + " (" + row_data[COLUMN.JOB5] + ")";
    }
    return programs;
  }
  function sanitize(arr) {
    if (arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].replace(/(^")|("$)/g, "").trim(); // remove leading and trailing quotes
      }
    }
    return arr;
  }
  function validateHeaders(headers) {
    var error = "";
    if (headers.length < COL_TOTAL) {
      // csv must have 40 columns
      error += "File must have " + COL_TOTAL + " tab-delimited columns.<br />";
      return error;
    }
    if (headers[COLUMN.FNAME].trim().toLowerCase() !== "First name".toLowerCase()) {
      error += "CSV file column " + (COLUMN.FNAME + 1) + " must be 'First Name'<br />";
    }
    if (headers[COLUMN.LNAME].trim().toLowerCase() !== "Last Name".toLowerCase()) {
      error += "CSV file column " + (COLUMN.LNAME + 1) + " must be 'Last Name'<br />";
    }
    if (headers[COLUMN.DEGREE].trim().toLowerCase() !== "Degree".toLowerCase()) {
      error += "CSV file column " + (COLUMN.DEGREE + 1) + " must be 'Degree'<br />";
    }
    if (headers[COLUMN.SPECIALTY].trim().toLowerCase() !== "Specialty".toLowerCase()) {
      error += "CSV file column " + (COLUMN.SPECIALTY + 1) + " must be 'Specialty'<br />";
    }
    if (headers[COLUMN.PRACTICE_TYPE].trim().toLowerCase() !== "Practice Type".toLowerCase()) {
      error += "CSV file column " + (COLUMN.PRACTICE_TYPE + 1) + " must be 'Practice Type'<br />";
    }
    if (headers[COLUMN.INSTITUTION].trim().toLowerCase() !== "Institution".toLowerCase()) {
      error += "CSV file column " + (COLUMN.INSTITUTION + 1) + " must be 'Institution'<br />";
    }
    if (headers[COLUMN.ADDRESS].trim().toLowerCase() !== "Address".toLowerCase()) {
      error += "CSV file column " + (COLUMN.ADDRESS + 1) + " must be 'Address'<br />";
    }
    if (headers[COLUMN.ADDRESS2].trim().toLowerCase() !== "Address 2".toLowerCase()) {
      error += "CSV file column " + (COLUMN.ADDRESS2 + 1) + " must be 'Address 2'<br />";
    }
    if (headers[COLUMN.CITY].trim().toLowerCase() !== "City".toLowerCase()) {
      error += "CSV file column " + (COLUMN.CITY + 1) + " must be 'City'<br />";
    }
    if (headers[COLUMN.STATE].trim().toLowerCase() !== "State".toLowerCase()) {
      error += "CSV file column " + (COLUMN.STATE + 1) + " must be 'State'<br />";
    }
    if (headers[COLUMN.ZIP].trim().toLowerCase() !== "Zip".toLowerCase()) {
      error += "CSV file column " + (COLUMN.ZIP + 1) + " must be 'Zip'<br />";
    }
    if (headers[COLUMN.PREFERRED_EMAIL].trim().toLowerCase() !== "Preferred Email".toLowerCase()) {
      error += "CSV file column " + (COLUMN.PREFERRED_EMAIL + 1) + " must be 'Preferred Email'<br />";
    }
    if (headers[COLUMN.ALTERNATE_EMAIL].trim().toLowerCase() !== "Alternate Email".toLowerCase()) {
      error += "CSV file column " + (COLUMN.ALTERNATE_EMAIL + 1) + " must be 'Alternate Email'<br />";
    }
    if (headers[COLUMN.PHONE1].trim().toLowerCase() !== "Preferred Phone 1".toLowerCase()) {
      error += "CSV file column " + (COLUMN.PHONE1 + 1) + " must be 'Preferred Phone 1'<br />";
    }
    if (headers[COLUMN.PHONE1_TYPE].trim().toLowerCase() !== "Phone Type".toLowerCase()) {
      error += "CSV file column " + (COLUMN.PHONE1_TYPE + 1) + " must be 'Phone Type'<br />";
    }
    if (headers[COLUMN.PHONE2].trim().toLowerCase() !== "Preferred Phone 2".toLowerCase()) {
      error += "CSV file column " + (COLUMN.PHONE2 + 1) + " must be 'Preferred Phone 2'<br />";
    }
    if (headers[COLUMN.PHONE2_TYPE].trim().toLowerCase() !== "Phone Type".toLowerCase()) {
      error += "CSV file column " + (COLUMN.PHONE2_TYPE + 1) + " must be 'Phone Type'<br />";
    }
    if (headers[COLUMN.FAX].trim().toLowerCase() !== "Fax Number".toLowerCase()) {
      error += "CSV file column " + (COLUMN.FAX + 1) + " must be 'Fax Number'<br />";
    }
    if (headers[COLUMN.NPI].trim().toLowerCase() !== "NPI".toLowerCase()) {
      error += "CSV file column " + (COLUMN.NPI + 1) + " must be 'NPI'<br />";
    }
    if (headers[COLUMN.OPTIN_TEXT].trim().toLowerCase() !== "Opt in for Texts".toLowerCase()) {
      error += "CSV file column " + (COLUMN.OPTIN_TEXT + 1) + " must be 'Opt in for Texts'<br />";
    }
    if (headers[COLUMN.SLN].trim().toLowerCase() !== "SLN".toLowerCase()) {
      error += "CSV file column " + (COLUMN.SLN + 1) + " must be 'SLN'<br />";
    }
    if (headers[COLUMN.BACKGROUND_DATE].trim().toLowerCase() !== "Background Check Completion Date".toLowerCase()) {
      error += "CSV file column " + (COLUMN.BACKGROUND_DATE + 1) + " must be 'Background Check Completion Date'<br />";
    }
    if (headers[COLUMN.BACKGROUND_STATUS].trim().toLowerCase() !== "Background Check Status".toLowerCase()) {
      error += "CSV file column " + (COLUMN.BACKGROUND_STATUS + 1) + " must be 'Background Check Status'<br />";
    }
    if (headers[COLUMN.OPTIN_PROGRAMS].trim().toLowerCase() !== "Opted In To Be Considered for Future Programs / Surveys".toLowerCase()) {
      error += "CSV file column " + (COLUMN.OPTIN_PROGRAMS + 1) + " must be 'Opted In To Be Considered for Future Programs / Surveys'<br />";
    }
    if (headers[COLUMN.OPTIN_NETWORK].trim().toLowerCase() !== "Opted Into Effectus PI Preferred Network".toLowerCase()) {
      error += "CSV file column " + (COLUMN.OPTIN_NETWORK + 1) + " must be 'Opted Into Effectus PI Preferred Network'<br />";
    }
    if (headers[COLUMN.PREVIOUSLY_PI].trim().toLowerCase() !== "Previous PI Experience".toLowerCase()) {
      error += "CSV file column " + (COLUMN.PREVIOUSLY_PI + 1) + " must be 'Previous PI Experience'<br />";
    }
    if (headers[COLUMN.COMPLETED_EFFECTUS_CONSENT].trim().toLowerCase() !== "Completed Effectus Network Consent Form".toLowerCase()) {
      error += "CSV file column " + (COLUMN.COMPLETED_EFFECTUS_CONSENT + 1) + " must be 'Completed Effectus Network Consent Form'<br />";
    }
    if (headers[COLUMN.COMPLETED_PI_NETWORK].trim().toLowerCase() !== "Completed PI Preferred Network Form".toLowerCase()) {
      error += "CSV file column " + (COLUMN.COMPLETED_PI_NETWORK + 1) + " must be 'Completed PI Preferred Network Form'<br />";
    }
    if (headers[COLUMN.OPTOUT].trim().toLowerCase() !== "Opt Out / Unsubscribe".toLowerCase()) {
      error += "CSV file column " + (COLUMN.OPTOUT + 1) + " must be 'Opt Out / Unsubscribe'<br />";
    }
    if (headers[COLUMN.JOB1].trim().toLowerCase() !== "Job # 1".toLowerCase()) {
      error += "CSV file column " + (COLUMN.JOB1 + 1) + " must be 'Job # 1'<br />";
    }
    if (headers[COLUMN.PROGRAM1].trim().toLowerCase() !== "Program 1".toLowerCase()) {
      error += "CSV file column " + (COLUMN.PROGRAM1 + 1) + " must be 'Program 1'<br />";
    }
    if (headers[COLUMN.JOB2].trim().toLowerCase() !== "Job # 2".toLowerCase()) {
      error += "CSV file column " + (COLUMN.JOB2 + 1) + " must be 'Job # 2'<br />";
    }
    if (headers[COLUMN.PROGRAM2].trim().toLowerCase() !== "Program 2".toLowerCase()) {
      error += "CSV file column " + (COLUMN.PROGRAM2 + 1) + " must be 'Program 2'<br />";
    }
    if (headers[COLUMN.JOB3].trim().toLowerCase() !== "Job # 3".toLowerCase()) {
      error += "CSV file column " + (COLUMN.JOB3 + 1) + " must be 'Job # 3'<br />";
    }
    if (headers[COLUMN.PROGRAM3].trim().toLowerCase() !== "Program 3".toLowerCase()) {
      error += "CSV file column " + (COLUMN.PROGRAM3 + 1) + " must be 'Program 3'<br />";
    }
    if (headers[COLUMN.JOB4].trim().toLowerCase() !== "Job # 4".toLowerCase()) {
      error += "CSV file column " + (COLUMN.JOB4 + 1) + " must be 'Job # 4'<br />";
    }
    if (headers[COLUMN.PROGRAM4].trim().toLowerCase() !== "Program 4".toLowerCase()) {
      error += "CSV file column " + (COLUMN.PROGRAM4 + 1) + " must be 'Program 4'<br />";
    }
    if (headers[COLUMN.JOB5].trim().toLowerCase() !== "Job # 5".toLowerCase()) {
      error += "CSV file column " + (COLUMN.JOB5 + 1) + " must be 'Job # 5'<br />";
    }
    if (headers[COLUMN.PROGRAM5].trim().toLowerCase() !== "Program 5".toLowerCase()) {
      error += "CSV file column " + (COLUMN.PROGRAM5 + 1) + " must be 'Program 5'<br />";
    }
    if (headers[COLUMN.NOTES].trim().toLowerCase() !== "Notes".toLowerCase()) {
      error += "CSV file column " + (COLUMN.NOTES + 1) + " must be 'Notes'<br />";
    }
    return error;
  }
});