define('effectus-app/helpers/format-string-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatStringDate = formatStringDate;
  function formatStringDate(params) {
    try {
      //return new Date(params[0]).toLocaleDateString(); 
      if (params && params[0] && params[0].trim() != '') {
        let date = params[0].split('T')[0];
        let date_parts = date.split('-');
        let year = date_parts[0];
        let month = date_parts[1];
        let day = date_parts[2];
        let date_formatted = month + '/' + day + '/' + year;
        return date_formatted;
      } else {
        return "";
      }
    } catch (e) {
      return "";
    }
  }

  exports.default = Ember.Helper.helper(formatStringDate);
});