define('effectus-app/routes/patienttrackingselect', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    session: Ember.inject.service('session'),
    progr: Ember.inject.service('current-program'),

    beforeModel() {

      if (this.get('progr').getProgram() == null) {
        this.transitionTo('index');
      }
      if (this.get('progr').getRole() == 'recruiter') {
        this.transitionTo('physicians');
      }
      if (this.get('progr').getRole() == 'trainer' || this.get('progr').getRole() == 'doctor') {
        this.transitionTo('patientlog');
      }
      if (this.get('progr').getRole() == null) {
        this.transitionTo('index');
      }
    },

    model(params) {
      return Ember.RSVP.hash({
        physicianids: this.store.query('physicianid', { study_id: this.get('progr').getProgram() })
      });
    },

    setupController(controller, model) {
      controller.set('ids', model.physicianids);
      //console.log("inside setup Application.js");
    },
    actions: {

      setSelection: function (selected) {
        this.set('selectedOption', selected);
        //console.log(this.get('selectedOption'))
      }

    }

  });
});