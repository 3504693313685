define('effectus-app/services/cognito', ['exports', 'ember-cognito/services/cognito', 'effectus-app/config/environment'], function (exports, _cognito, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const cognitoEnv = Ember.merge({
    autoRefreshSession: false
  }, _environment.default.cognito);

  exports.default = _cognito.default.extend({
    poolId: cognitoEnv.poolId,
    clientId: cognitoEnv.clientId,
    autoRefreshSession: cognitoEnv.autoRefreshSession
  });
});