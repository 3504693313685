define('effectus-app/routes/physicians/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    progr: Ember.inject.service('current-program'),

    beforeModel() {

      if (this.get('progr').getProgram() == null) this.transitionTo('index');

      if (this.get('progr').getRole() == 'trainer' || this.get('progr').getRole() == 'doctor') {
        this.transitionTo('patientlog');
      }

      if (this.get('progr').getRole() == null) {
        this.transitionTo('index');
      }
    },

    model(params) {

      return Ember.RSVP.hash({
        //physician: this.store.findRecord('physician', params.library_id),
        physician: this.store.findRecord('physician', params.library_id, { adapterOptions: { query: { study_id: this.get('progr').getProgram() } } }),
        //physician: this.store.queryRecord('physician', {filter:{study_id: this.get('progr').getProgram(), physician_id:params.library_id}}),
        sitenum: this.store.query('sitenum', { study_id: this.get('progr').getProgram() }),
        specialty: this.store.findAll('specialty'),
        degree: this.store.findAll('degree'),
        state: this.store.findAll('state'),
        program: this.store.query('program', { study_id: this.get('progr').getProgram() }),
        phonetype: this.store.findAll('phonetype')

      });
    },
    setupController(controller, model) {
      //console.log('setup a controller');

      controller.set('physician', model.physician);
      controller.set('sitenum', model.sitenum);
      controller.set('specialty', model.specialty);
      controller.set('degree', model.degree);
      controller.set('state', model.state);
      controller.set('program', model.program);
      controller.set('phonetype', model.phonetype);

      //console.log('setup controller');
    },

    actions: {
      setDegree(physician, selected) {
        physician.set('degree', selected);
      },
      setSpecial(physician, selected) {
        physician.set('speciality', selected);
      },
      setState(physician, selected) {
        physician.set('state', selected);
      },
      setLicenseState(physician, selected) {
        physician.set('license_state', selected);
      },
      setLicenseState2(physician, selected) {
        physician.set('license_state2', selected);
      },
      setLicenseState3(physician, selected) {
        physician.set('license_state3', selected);
      },
      setPhoneTypePreferred(physician, selected) {
        physician.set('phone_preferred_type', selected);
      },
      setPhoneTypeAlternate(physician, selected) {
        physician.set('phone_alternate_type', selected);
      },
      savePhysician(physician) {
        physician.set('audit_modifiedby', this.get('progr').getEmail());
        physician.save().then(() => {
          this.controller.set('errorMessage', '');
          this.transitionTo('physiciantracking');
        }).catch(res => {
          this.controller.set('errorMessage', res.errors[0].detail);
        });
      },

      willTransition(transition) {

        let model = this.controller.get('physician');

        if (model.get('hasDirtyAttributes')) {
          let confirmation = confirm("Your changes haven't saved yet. Would you like to leave this form?");

          if (confirmation) {
            model.rollbackAttributes();
            this.controller.set('errorMessage', '');
          } else {
            transition.abort();
          }
        }
      }
    }
  });
});