define("effectus-app/models/study", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    study_id: _emberData.default.attr("number"),
    site_id: _emberData.default.attr("number"),
    name: _emberData.default.attr("string"),
    client: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    criteria: _emberData.default.attr("string"),
    status: _emberData.default.attr("string"),
    is_active: _emberData.default.attr("boolean"),
    for_training: _emberData.default.attr("boolean"),
    reify_trial_id: _emberData.default.attr("string"),
    audit_modified: _emberData.default.attr("string"),
    audit_modifiedby: _emberData.default.attr("string")
  });
});