define('effectus-app/routes/invoiceupload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    progr: Ember.inject.service('current-program'),
    exportCsvService: Ember.inject.service('export-csv'),

    beforeModel() {

      if (this.get('progr').getPhysician() == null) {
        if (this.get('progr').getRole() == 'admin' || this.get('progr').getRole() == 'trainer') {
          this.transitionTo('patientlogselect');
        }
      }

      if (this.get('progr').getProgram() == null || this.get('progr').getProgram() === 0) {
        this.transitionTo('index');
      }

      if (this.get('progr').getRole() == 'recruiter') {
        this.transitionTo('physicians');
      }

      if (this.get('progr').getRole() == null) {
        this.transitionTo('index');
      }
    },

    model() {
      return Ember.RSVP.hash({
        physicianinvoice: this.store.query('physicianinvoice', {
          study_id: this.get('progr').getProgram(),
          physician_id: this.get('progr').getPhysician()
        }),
        program: this.store.query('program', {
          study_id: this.get('progr').getProgram(),
          phys_em: this.get('progr').getEmail()
        })
      });
    },
    setupController(controller, model) {
      controller.set('physicianinvoice', model.physicianinvoice);
      controller.set('program', model.program);
    },

    actions: {
      willTransition: function () {
        this.controller.set('statusMessage', '');
        this.controller.set('errorMessage', '');
      },
      upload(event) {
        this.controller.set('statusMessage', 'Uploading file...');
        this.controller.set('errorMessage', '');
        const reader = new FileReader();
        const file = event.target.files[0];
        const size = file.size;
        const type = file.type;
        const filename = file.name;
        const accepted_mime_types = ['application/pdf', 'application/msword', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        if (new RegExp(accepted_mime_types.join('|')).test(type)) {
          //if file type is accepted
          // Note: reading file is async
          reader.onload = () => {
            let readerResult = reader.result;
            let fileBlob = readerResult.substr(readerResult.indexOf(",") + 1);
            let invoice = this.store.createRecord('physicianinvoice');
            invoice.set('study_id', this.get('progr').getProgram());
            invoice.set('site_id', this.get('progr').getSite());
            invoice.set('physician_id', this.get('progr').getPhysician());
            invoice.set('mime_type', type);
            invoice.set('file_size', size);
            invoice.set('file_blob', fileBlob);
            invoice.set('extension', getFileExtension(filename));
            invoice.set('audit_modifiedby', this.get('progr').getEmail());
            invoice.save().then(() => {
              this.controller.set('statusMessage', 'File "' + filename + '" uploaded successfully.');
            }).catch(res => {
              let errorMessage = res.errors[0].detail;
              this.controller.set('statusMessage', '');
              this.controller.set('errorMessage', errorMessage);
            });
          };

          if (file) {
            reader.readAsDataURL(file);
          }
        } else {
          this.controller.set('statusMessage', '');
          this.controller.set('errorMessage', "File must be .pdf, .doc, .docx, .xls, or .xlsx.");
        }
      }
    }
  });


  function getFileExtension(filename) {
    return filename.split('.').pop();
  }
});