define('effectus-app/controllers/patientlog', ['exports', 'amazon-cognito-identity-js', 'effectus-app/utils/data-util'], function (exports, _amazonCognitoIdentityJs, _dataUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    progr: Ember.inject.service('current-program'),
    init: function () {
      this.set('editPatientID', 0); //the patient being edited
      /*** messy code need to refactor new patient code - similar to patient tracking  */
      this.set('isNewPatientNoData', false); //newly created patient with no data updated
      this.set('newPatientQuestionKey', ''); //key for question being answered
      this.set('newPatientAnswers', {}); //list of form answers for new patient form
      this.set('requiredAnswers', {}); //list of form answers for new patient form    
      this.set('newPatientID', 0); //4-digit id of new patient
      this.set('newPatientDateConsult', ''); //date consult of new patient
      this.set('newPatientAge', ''); //age of new patient  
      //*** end new patient */
      this.set('isProcessing', false); //display loading image
      this.set('errorMessage', "");
      this.set('errorAddMessage', "");
      if (!this.get('dataUtil')) {
        this.set('dataUtil', _dataUtil.default.create()); //has formatting for json (in controller) and dates (used in router)
      }
      this._super();
    },
    actions: {
      /* add new patient form actions */
      openNewPatientForm() {
        this.set('isAddingNewPatient', true);
        this.set('isProcessing', false);
        this.set('newPatientQuestionKey', '');
        this.set('isNewPatientNoData', true); //new form with blank data
        let questionKeys = buildQuestionKeys(this.get('question'));
        this.set('newPatientAnswers', questionKeys);
      },

      cancelNewPatientForm() {
        this.set('isNewPatientNoData', false); //newly created patient with no data updated
        this.set('newPatientQuestionKey', ''); //key for question being answered
        this.set('newPatientAnswers', {}); //list of form answers for new patient form
        this.set('requiredAnswers', {}); //list of form answers for new patient form
        this.set('newPatientID', 0); //4-digit id of new patient
        this.set('newPatientDateConsult', ''); //date consult of new patient
        this.set('newPatientAge', ''); //age of new patient        
        this.set('isAddingNewPatient', false);
        this.set('isProcessing', false);
        this.set('errorMessage', "");
        this.set('errorAddMessage', '');
        this.set('isNewPatientNoData', false);
      },
      setNewPatientNo: function (value) {
        this.set('isNewPatientNoData', false); //new form with data
        this.set('newPatientID', value);
      },
      setNewPatientDateConsult(value) {
        this.set('isNewPatientNoData', false); //new form with data
        this.set('newPatientDateConsult', value);
      },
      setNewPatientAge(value) {
        if (typeof value === 'string') {
          value = value.trim();
        }
        this.set('isNewPatientNoData', false); //new form with data
        this.set('newPatientAge', value);
      },
      setNewQuestionKey: function (question) {
        this.set('isNewPatientNoData', false); //new form with data
        this.set('newPatientQuestionKey', question);
      },

      setNewAnswer: function (value) {
        let question_key = this.get('newPatientQuestionKey');
        let answers = this.get('newPatientAnswers');
        answers[question_key] = value;
        this.set('isNewPatientNoData', false); //new form with data
        //patient.set('patient_answers', JSON.stringify(patient_answers)) ;    
      },

      setNewAnswerCheckbox: function (selected) {
        let question_key = this.get('newPatientQuestionKey');
        let answers = this.get('newPatientAnswers');
        let checked_answers = answers[question_key];

        let arr_answers = [];
        if (checked_answers) arr_answers = checked_answers.split(','); //patient_answers.split(',');

        //make comma delimited string of checked options
        if (arr_answers.includes(selected)) {
          const index = arr_answers.indexOf(selected);
          arr_answers.splice(index, 1);
        } else {
          arr_answers.push(selected);
        }
        let new_checked_answers = arr_answers.join();
        answers[question_key] = new_checked_answers;
        this.set('isNewPatientNoData', false); //new form with data
      },

      /* edit patient form actions */
      setQuestionKey: function (patient, question) {
        this.set('patienttracking', patient);
        this.get('patienttracking').set('question_key', question);
      },

      setTextAnswer: function (value) {
        if (typeof value === 'string') {
          value = value.trim();
        }
        setAnswer(this.get('patienttracking'), value);
      },

      setAnswerSelect: function (selected) {
        setAnswer(this.get('patienttracking'), selected);
      },

      setAnswerRadio: function (selected) {
        setAnswer(this.get('patienttracking'), selected);
      },

      setAnswerDate: function (selected) {
        setAnswer(this.get('patienttracking'), selected);
      },

      setAnswerCheckbox: function (key, event) {
        let selected = event.target.value;
        let patient = this.get('patienttracking');
        let str_patient_answers = patient.get('patient_answers');
        let json_patient_answers = JSON.parse(str_patient_answers);
        let answers = json_patient_answers[key];
        let arr_answers = answers.split(',');

        if (arr_answers.includes(selected)) {
          const index = arr_answers.indexOf(selected);
          arr_answers.splice(index, 1);
        } else {
          arr_answers.push(selected);
        }

        let new_answers = arr_answers.join();
        setAnswer(patient, new_answers);
      },

      doSomethingOnClose(patient) {
        patient.set('audit_modifiedby', this.get('progr').getEmail());
        //patient.save();
      },

      switch() {
        this.transitionToRoute('patientlogselect');
      }
    }
  });


  /* build object with question keys and blank answers */
  function buildQuestionKeys(questions) {
    let questionKeys = {};
    questions.forEach(function (question) {
      let key = question.get('question_key');
      questionKeys[key] = '';
    });
    return questionKeys;
  }

  function setAnswer(patient, answer) {
    let patient_answers = JSON.parse(patient.get('patient_answers'));
    let question_key = patient.get('question_key');
    patient_answers[question_key] = answer;
    patient.set('patient_answers', JSON.stringify(patient_answers));

    //console.log("patient_answer", patient.get('patient_answers'));
  }
});