define('effectus-app/models/physicianstudymap', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    physician_id: _emberData.default.attr('number'),
    fname: _emberData.default.attr('string'),
    lname: _emberData.default.attr('string'),
    email_preferred: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    study_id: _emberData.default.attr('number'),
    site_id: _emberData.default.attr('number'),
    audit_modifiedby: _emberData.default.attr('string')
  });
});