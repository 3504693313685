define("effectus-app/models/pinetwork", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    full_name: _emberData.default.attr("string"),
    working_with_effectus: _emberData.default.attr("string"),
    degree: _emberData.default.attr("string"),
    specialty: _emberData.default.attr("string"),
    specialty_other: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),
    phone_cell: _emberData.default.attr("string"),
    phone_office: _emberData.default.attr("string"),
    phone_fax: _emberData.default.attr("string"),
    practice_name: _emberData.default.attr("string"),
    practice_address: _emberData.default.attr("string"),
    practice_address_2: _emberData.default.attr("string"),
    practice_city: _emberData.default.attr("string"),
    practice_state: _emberData.default.attr("string"),
    practice_zip: _emberData.default.attr("string"),
    practice_country: _emberData.default.attr("string"),
    practice_website: _emberData.default.attr("string"),
    practice_setting_type: _emberData.default.attr("string"),
    practice_size: _emberData.default.attr("string"),
    pi_or_subi: _emberData.default.attr("string"),
    therapeutic_area: _emberData.default.attr("string"),
    therapeutic_area_other: _emberData.default.attr("string"),
    years_experience_phase_1: _emberData.default.attr("number"),
    years_experience_phase_2: _emberData.default.attr("number"),
    years_experience_phase_3: _emberData.default.attr("number"),
    years_experience_phase_4: _emberData.default.attr("number"),
    trials_conducted_2yrs: _emberData.default.attr("number"),
    office_trials_conducted: _emberData.default.attr("number"),
    irb_type_used: _emberData.default.attr("string"),
    documented_training: _emberData.default.attr("string"),
    resume_cv: _emberData.default.attr("string"),
    date_form_received: _emberData.default.attr("date"),
    notes: _emberData.default.attr("string"),
    audit_modifiedby: _emberData.default.attr("string"),
    temp_notes: _emberData.default.attr("string"),
    editMode: _emberData.default.attr("boolean"),
    displayMessage: _emberData.default.attr("string")
  });
});