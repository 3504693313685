define("effectus-app/controllers/pinetwork", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service("session"),
    init: function () {
      this._super(...arguments);
      this.set("searchSpecialty", "");
    },
    actions: {
      reset() {
        this.set("searchTerm", "");
        this.set("searchSpecialty", "");
        document.getElementById("specialtyDropdown").selectedIndex = 0;
        this.set("piNetworkFiltered", this.get("piNetwork"));
      },
      search() {
        let specialty = this.get("searchSpecialty");

        let piNetworkFiltered = this.get("piNetwork"); //start with full list

        if (specialty !== "") {
          piNetworkFiltered = piNetworkFiltered.filterBy("specialty", specialty);
          this.set("searchTerm", "Searching on specialty '" + specialty + "'.");
        }

        this.set("piNetworkFiltered", piNetworkFiltered);
      },
      setSpecialty(selected) {
        this.set("searchSpecialty", selected);
      },
      editNote(pi) {
        pi.set("displayMessage", "");
        pi.set("temp_notes", pi.get("notes"));
        pi.set("editMode", true);
      },
      cancelNote(pi) {
        pi.set("editMode", false);
        pi.set("displayMessage", "");
        pi.set("temp_notes", "");
      }
    }
  });
});