define("effectus-app/controllers/enlist", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service("session"),
    progr: Ember.inject.service("current-program"),
    init: function () {
      this._super(...arguments);
      this.set("statusMessage", "");
      this.set("isProcessing", false);
      this.set("editID", 0); //the hcp being edited
      this.set("isEditing", true);
      this.set("searchName", "");
      this.set("searchFuzzyName", "");
      this.set("searchEmail", "");
    },
    actions: {
      import: function (event) {
        this.set("isProcessing", true);
        document.querySelector("input").addEventListener("cancel", evt => {
          this.set("statusMessage", "");
          this.set("isEditing", true);
          this.set("isProcessing", false);
          this.set("editID", 0); //the hcp being edited
        });
      },
      clear: function (event) {
        this.set("statusMessage", "");
        this.set("isEditing", false);
        this.set("isProcessing", false);
        this.set("showProcessingMessage", false);
        this.set("editID", 0); //the hcp being edited
      },
      setSearchName: function (value) {
        this.set("searchName", value.trim());
        this.set("searchFuzzyName", fuzzyMatch(value.trim()));
      },
      setSearchEmail: function (value) {
        this.set("searchEmail", value.trim());
      },
      resetSearch: function (event) {
        this.set("searchName", "");
        this.set("searchFuzzyName", "");
        this.set("searchEmail", "");
        this.set("enlistFiltered", this.get("enlist"));
      },
      search: function (event) {
        let email = this.get("searchEmail");
        let name = this.get("searchName");
        let enlistFiltered = this.get("enlist"); //start with full list
        if (email || name) {
          if (email) {
            //enlistFiltered = enlistFiltered.filterBy("email_alternate", email);
            enlistFiltered = enlistFiltered.filter(item => {
              // Check if the email matches either email_preferred or email_alternate
              return item.get("email_preferred") === email || item.get("email_alternate") === email;
            });
          }
          if (name) {
            const regexPattern = `^${name}$`;

            enlistFiltered = enlistFiltered.filter(item => {
              // Check if the first name and last name together match the provided name
              const fullNameMatch = `${item.get("fname")} ${item.get("lname")}`.match(new RegExp(regexPattern, "i"));

              // Check if either the first name or the last name individually matches the provided name
              const firstNameMatch = item.get("fname").match(new RegExp(regexPattern, "i"));
              const lastNameMatch = item.get("lname").match(new RegExp(regexPattern, "i"));

              // Return true if there is a full name match or if either first name or last name matches
              return fullNameMatch || firstNameMatch || lastNameMatch;
            });
          }
          this.set("enlistFiltered", enlistFiltered);
        }
      }
    }
  });


  function fuzzyMatch(input) {
    // Replace spaces with wildcard character (e.g., '*')
    return input.replace(/\s/g, "*");
  }
});