define('effectus-app/controllers/physiciantracking', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    session: Ember.inject.service('session'),
    progr: Ember.inject.service('current-program'),

    init: function () {
      this._super();
      this.set('isProcessing', false);
      this.set('errorMessage', '');
      this.set('editMode', false);
      this.set('editModePhysicianID', '');
    },
    actions: {
      editPhysician(physiciantracking) {
        this.set('editMode', true);
        this.set('editModePhysicianID', physiciantracking.get('physician_id'));
        physiciantracking.set('isEditing', true);
        physiciantracking.set('highlight', 'cell-selected');
      },
      cancelEditPhysician(physiciantracking) {
        this.set('editMode', false);
        this.set('editModePhysicianID', '');
        physiciantracking.set('isEditing', false);
        physiciantracking.set('highlight', '');
      },
      updatePhysicianSite(physiciantracking, site) {
        physiciantracking.set('site_id', site);
      }

    }
  });
});