define('effectus-app/components/invoicetracking/invoicetracking-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '', // don't wrap component in <div> because it's a <tr>

    actions: {
      save(item) {
        this.sendAction('save', item);
      },
      delete(item) {
        this.sendAction('delete', item);
      },
      edit(item) {
        this.sendAction('edit', item);
      },
      cancel(item) {
        this.sendAction('cancel', item);
      }
    }
  });
});