define('effectus-app/adapters/screeningstatus', ['exports', 'effectus-app/adapters/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({

		pathForType() {
			//		return 'getScreeningStatus?id_token=' + this.get('progr').getToken();
			return 'getScreeningStatus';
		}

	});
});