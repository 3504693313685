define('effectus-app/routes/index', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = Ember.Route.extend({
      session: Ember.inject.service('session'),
      cognito: Ember.inject.service(),
      progr: Ember.inject.service('current-program'),
      model(params, transition) {
         //query parameters will be attached to link when admins click on a link in patient tracking email notification
         //currently, this is the only time users can access an internal page using a link
         //user must still login        
         if (transition.queryParams) {
            //if query parameters exists
            if (transition.queryParams.pt && transition.queryParams.ph && transition.queryParams.st) {
               this.get('progr').set('pt_redirect', true);
               this.get('progr').setProgram(transition.queryParams.st);
               this.get('progr').setPhysician(transition.queryParams.ph);
               this.get('progr').setPatient(transition.queryParams.pt);
               if (this.get('session').get('isAuthenticated')) {
                  this.transitionTo('patienttracking');
               }
            }
         }
         try {
            return this.store.findAll('program');
         } catch (error) {
            //console.log('do nothing')
         }
      }

   });
});