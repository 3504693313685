define('effectus-app/controllers/physicianinvoice', ['exports', 'amazon-cognito-identity-js'], function (exports, _amazonCognitoIdentityJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    progr: Ember.inject.service('current-program'),
    init: function () {
      this.set('blob', null);
      this._super();
    }
  });
});