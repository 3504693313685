define('effectus-app/helpers/is-selected', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isSelected = isSelected;
  function isSelected(params /*, hash*/) {
    // console.log(params[0]);
    // console.log([params[1]]);

    // console.log(params[0].split('*||*'));
    var split = params[0].split('*||*');
    //console.log(split); 
    //console.log(typeof(params[1]));
    if (split[1] === params[1]) return "checked";else return "";
  }

  exports.default = Ember.Helper.helper(isSelected);
});