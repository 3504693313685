define('effectus-app/components/patientlog/new-patient-answer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            setQuestionKey(item) {
                this.sendAction('setNewQuestionKey', item);
            },
            setTextAnswer(item) {
                if (typeof item === 'string') {
                    item = item.trim();
                }
                this.sendAction('setNewAnswer', item);
            },
            setAnswer(item) {
                this.sendAction('setNewAnswer', item);
            },
            setAnswerCheckbox: function (value) {
                this.sendAction('setNewAnswerCheckbox', value);
            }
        }
    });
});