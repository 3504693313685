define('effectus-app/models/invoicetracking', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    studyId: _emberData.default.attr('number'),
    physicianId: _emberData.default.attr('number'),
    physicianFirstName: _emberData.default.attr('string'),
    physicianLastName: _emberData.default.attr('string'),
    invoiceNumber: _emberData.default.attr('string'),
    invoiceDate: _emberData.default.attr('date'),
    dateServicesStart: _emberData.default.attr('date'),
    dateServicesEnd: _emberData.default.attr('date'),
    servicesHours: _emberData.default.attr('number'),
    servicesDescription: _emberData.default.attr('string'),
    hourlyRate: _emberData.default.attr('number'),
    amountOwed: _emberData.default.attr('number'),
    datePaid: _emberData.default.attr('date'),
    checkNumber: _emberData.default.attr('string'),
    dateCheckMailed: _emberData.default.attr('date'),
    notes: _emberData.default.attr('string'),
    auditModifiedby: _emberData.default.attr('string'),

    physicianLastFirstName: Ember.computed('physicianFirstName', 'physicianLastName', function () {
      if (!this.get('physicianLastName') || !this.get('physicianFirstName')) {
        return "";
      }
      return `${this.get('physicianLastName')}, ${this.get('physicianFirstName')}`;
    }),

    /**
     * Return whether this record can be deleted
     * Rules - Must be saved to database (meaning it has an ID assigned) in order to be deleted
     */
    canBeDeleted: Ember.computed('id', function () {
      return this.get('id');
    })
  });
});