define('effectus-app/utils/cognito-storage', ['exports', 'ember-cognito/utils/cognito-storage'], function (exports, _cognitoStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _cognitoStorage.default;
    }
  });
});