define('effectus-app/helpers/is-equal-answer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEqualAnswer = isEqualAnswer;
  function isEqualAnswer(params /*, hash*/) {
    //console.log("params[0]=", params[0], " & ", "params[1]=", params[1]);
    // console.log(params[0].split('*||*'));
    var split = params[0].split('*||*');
    //console.log(split[1], " = ", params[1]);

    return split[1] === params[1];
  }

  exports.default = Ember.Helper.helper(isEqualAnswer);
});