define('effectus-app/helpers/format-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDate = formatDate;
  function formatDate(params) {
    try {
      if (params) {
        let str_date = new Date(params).toISOString();
        if (str_date.trim() != '') {
          let date = str_date.split('T')[0];
          let date_parts = date.split('-');
          let year = date_parts[0];
          let month = date_parts[1];
          let day = date_parts[2];
          let date_formatted = month + '/' + day + '/' + year;
          return date_formatted;
        }
      }
      return "";
    } catch (e) {
      return "";
    }
  }

  exports.default = Ember.Helper.helper(formatDate);
});