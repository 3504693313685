define('effectus-app/routes/physicians/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    progr: Ember.inject.service('current-program'),

    beforeModel() {

      if (this.get('progr').getProgram() == null) this.transitionTo('index');

      if (this.get('progr').getRole() == 'trainer' || this.get('progr').getRole() == 'doctor') {
        this.transitionTo('patientlog');
      }

      if (this.get('progr').getRole() == null) {
        this.transitionTo('index');
      }
    },

    model() {
      return Ember.RSVP.hash({
        physician: this.store.query('physician', { study_id: this.get('progr').getProgram() }),
        program: this.store.query('program', { study_id: this.get('progr').getProgram() })
      });
    },

    setupController(controller, model) {
      //console.log('setup a controller');

      controller.set('program', model.program);
      controller.set('physician', model.physician);

      //console.log('setup controller');
    },

    actions: {

      deletePhysician(physician) {
        let confirmation = confirm('Are you sure you want to delete this physician?');

        if (confirmation) {
          physician.set('audit_modifiedby', this.get('progr').getEmail());
          //as per Molly V. HCP should never be deleted
          //physician.destroyRecord();
        }
      }
    }

  });
});