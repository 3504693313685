define('effectus-app/components/invoicetracking/invoicetracking-cell', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '', // don't wrap component in <div> because it's a <td>
    init: function () {
      this._super();
      if (this.item.get('isNewInvoice')) {
        this.item.set('highlightCell', 'cell-selected');
      }
    },
    actions: {
      /**
       * Start editing the given field
       * @param item - model object being editing
       * @param fieldName - name of field that's about to be edited
       */
      edit: function (item, fieldName) {
        item.set('highlightCell', 'cell-selected');
      },

      /**
       * Cancel an edit of the given field
       * @param item - model object being edited
       * @param fieldName - name of field to rollback
       */
      cancel: function (item, fieldName) {
        item.rollbackAttributes();
        item.set('highlightCell', '');
        this.sendAction('cancel', item);
      },

      /**
       * Save results of editing field
       * @param item - model object being edited
       * @param fieldName - name of field that was edited
       */
      save: function (item, fieldName) {
        //item.set('isNewInvoice', false) ;
        this.sendAction('save', item);
      },

      /**
       * Delete record
       * @param item - model object being edited
       * @param fieldName - name of field that was edited
      */
      delete: function (item, fieldName) {
        this.sendAction('delete', item);
      }
    }
  });
});