define('effectus-app/models/patientlog', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    patient_deid_number: _emberData.default.attr('string'),
    study_id: _emberData.default.attr('number'),
    site_id: _emberData.default.attr('number'),
    site_code: _emberData.default.attr('string'),
    physician_id: _emberData.default.attr('number'),
    physician_fname: _emberData.default.attr('string'),
    physician_lname: _emberData.default.attr('string'),
    date_consult: _emberData.default.attr('date'),
    patient_age: _emberData.default.attr('string'),
    locked: _emberData.default.attr('number'), //if 1 then locked for HCPs. Admins can always edit this record
    //list of questions for the program
    questions: _emberData.default.attr('string'), //list of questions and original answers, old code (use patient_answers instead?)
    patient_answers: _emberData.default.attr(), // new patient answers 
    answers_required: _emberData.default.attr(''),
    audit_modified: _emberData.default.attr('date'),
    audit_modifiedby: _emberData.default.attr('string'),
    compQuestions: Ember.computed('questions', function () {
      return this.get('questions').split('----');
    })

  });
});