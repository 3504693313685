define('effectus-app/routes/physicianinvoice', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    progr: Ember.inject.service('current-program'),
    exportCsvService: Ember.inject.service('export-csv'),

    beforeModel() {

      if (this.get('progr').getProgram() == null || this.get('progr').getProgram() === 0) {
        this.transitionTo('index');
      }

      if (this.get('progr').getRole() == 'recruiter') {
        this.transitionTo('physicians');
      }

      if (this.get('progr').getRole() == 'trainer') {
        this.transitionTo('patientlog');
      }

      if (this.get('progr').getRole() == null) {
        this.transitionTo('index');
      }
    },

    model() {
      return Ember.RSVP.hash({
        physicianinvoice: this.store.query('physicianinvoice', {
          study_id: this.get('progr').getProgram()
        }),
        program: this.store.query('program', { study_id: this.get('progr').getProgram() })
      });
    },
    setupController(controller, model) {
      controller.set('physicianinvoice', model.physicianinvoice);
      controller.set('program', model.program);
    },

    actions: {
      willTransition: function () {},
      deleteInvoice(invoice) {
        let confirmation_txt = 'Are you sure you want to delete this invoice?';
        let confirmation = confirm(confirmation_txt);
        if (confirmation) {
          invoice.set('audit_modifiedby', this.get('progr').getEmail());
          invoice.destroyRecord().then(() => {
            this.refresh();
          });
        }
      },

      viewInvoice(invoice) {
        let filename = invoice.get('study_id') + '_' + invoice.get('physician_id') + '_' + new Date(invoice.get('uploaded_date')).toISOString() + '.' + invoice.get('extension');
        showFile(invoice.get('file_blob'), invoice.get('mime_type'), filename);
        if (!invoice.get('viewed_date')) {
          var now = new Date();
          invoice.set('audit_modifiedby', this.get('progr').getEmail());
          invoice.set('downloaded_date', now);
          invoice.save().then(() => {
            this.refresh();
          });
        }
      }
    }
  });


  function showFile(blob, mime_type, filename) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var bin = window.atob(blob);
    var ab = s2ab(bin);
    var newBlob = new Blob([ab], { type: mime_type });
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    /*   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }  */

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = filename;
    document.body.appendChild(link); // Add the link to DOM
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
});