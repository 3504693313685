define('effectus-app/helpers/is-time-break', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isTimeBreak = isTimeBreak;
  function isTimeBreak(params /*, hash*/) {
    return true;
  }

  exports.default = Ember.Helper.helper(isTimeBreak);
});