define('effectus-app/routes/physicians/assign', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        progr: Ember.inject.service('current-program'),

        beforeModel() {
            if (this.get('progr').getProgram() == null) this.transitionTo('index');

            if (this.get('progr').getRole() == 'trainer' || this.get('progr').getRole() == 'doctor') {
                this.transitionTo('patientlog');
            }

            if (this.get('progr').getRole() == null) {
                this.transitionTo('index');
            }
        },

        model() {
            return Ember.RSVP.hash({
                physician: this.store.query('physiciantracking', { study_id: this.get('progr').getProgram() }),
                available_physician: this.store.query('physicianstudymap', { study_id: this.get('progr').getProgram() }), // return physicians not assigned to study
                program: this.store.query('program', { study_id: this.get('progr').getProgram() }),
                sites: this.store.query('sitenum', { study_id: this.get('progr').getProgram() })
            });
        },

        setupController(controller, model) {
            controller.set('program', model.program);
            controller.set('physician', model.physician);
            controller.set('availablePhysicians', model.available_physician);
            controller.set('sites', model.sites);
        },

        actions: {

            addHCP() {
                let physician_id = this.controller.get('physician_id');
                let site_id = this.controller.get('site_id');
                let validate = "";
                if (!physician_id) {
                    validate = 'Physician is required.<br />';
                }
                if (!site_id) {
                    validate = validate + 'Site is required.<br />';
                }
                if (validate !== "") {
                    this.controller.set('errorMessage', validate);
                    return;
                }
                let assignPhysician = this.store.createRecord('physicianstudymap');
                assignPhysician.set('study_id', this.get('progr').getProgram());
                assignPhysician.set('site_id', site_id);
                assignPhysician.set('physician_id', physician_id);
                assignPhysician.set('audit_modifiedby', this.get('progr').getEmail());
                assignPhysician.save().then(() => {
                    this.controller.set('errorMessage', "");
                    this.controller.set('physician_id', "");
                    this.controller.set('site_id', "");
                    this.refresh();
                }).catch(res => {
                    let errorMessage = res.errors[0].detail;
                    this.controller.set('errorMessage', errorMessage);
                });
            },

            deleteHCP(physician) {

                let name = physician.get('lname').toUpperCase() + ', ' + physician.get('fname').toUpperCase();
                let confirmation = confirm('Are you sure you want to remove ' + name + ' from the program? All HCP screening and tracking data for this study will be deleted.');

                if (confirmation) {
                    physician.set('audit_modifiedby', this.get('progr').getEmail());
                    //remove from physician_tracking
                    physician.destroyRecord().then(() => {
                        this.controller.set('errorMessage', "");
                        this.controller.set('physician_id', "");
                        this.controller.set('site_id', "");
                        this.refresh();
                    }).catch(res => {
                        let errorMessage = res.errors[0].detail;
                        this.controller.set('errorMessage', errorMessage);
                    });
                }
            }
        }

    });
});