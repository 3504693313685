define("effectus-app/models/reasondisqualified", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    value: _emberData.default.attr("string"),
    key: _emberData.default.attr("string")
  });
});