define('effectus-app/controllers/physicians/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        isValid: Ember.computed.match('physician.site_id', /^[0-9]/),
        isDisabled: Ember.computed.not('isValid'),
        init: function () {
            this.set('errorMessage', "");
        },
        actions: {
            cancel(physician) {
                this.set('errorMessage', "");
                this.transitionToRoute('physicians');
                physician.rollbackAttributes();
            }
        }
    });
});