define('effectus-app/components/patienttracking/edit-patient-answer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            setQuestionKey(value) {
                this.sendAction('setQuestionKey', value);
            },
            setTextAnswer(value) {
                if (typeof value === 'string') {
                    value = value.trim();
                }
                this.sendAction('setAnswer', this.get('patient'), value);
            },
            setAnswer(value) {
                this.sendAction('setAnswer', this.get('patient'), value);
            },
            setAnswerCheckbox(value) {
                this.sendAction('setAnswerCheckbox', this.get('patient'), value);
            },
            setAnswerDTDate(value) {
                this.sendAction('setAnswerDTDate', this.get('patient'), value);
            },
            setAnswerDTTime(value) {
                this.sendAction('setAnswerDTTime', this.get('patient'), value);
            }
        }
    });
});