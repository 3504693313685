define('effectus-app/controllers/index', ['exports', 'effectus-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    cognito: Ember.inject.service(),
    session: Ember.inject.service(),
    cognitoUser: Ember.computed.readOnly('cognito.user'),

    progr: Ember.inject.service('current-program'),
    selectedOption: null,

    needs: ['application'],
    currentPath: Ember.computed.alias('controllers.application.currentPath'),

    headerMessage: 'Coming Soon',
    responseMessage: '',
    emailAddress: '',
    isLoadHCPProgramsDone: false,
    isValid: Ember.computed.notEmpty('selectedOption'),
    isDisabled: Ember.computed.not('isValid'),
    init: function () {
      if (this.get('progr').getHCPPrograms()) {
        this.get('progr').set('hcp_progr', this.get('progr').getHCPPrograms());
      }
      if (this.get('progr').getHCPSites()) {
        this.get('progr').set('hcp_sites', this.get('progr').getHCPSites());
      }
      this.set('signupURL', _environment.default.cognitoLinkSignup + _environment.default.cognito.clientId);
      this.set('forgotPasswordURL', _environment.default.cognitoLinkForgotPassword + _environment.default.cognito.clientId);
      this.set('isLoadHCPProgramsDone', false);
    },
    actions: {

      setSelection: function (selected) {
        this.set('selectedOption', selected);
      },

      invalidateSession() {
        this.get('progr').setToken(null);
        this.get('progr').setProgram(null);
        this.get('progr').setRole(null);
        this.get('progr').setPhysician(null);
        this.get('progr').setName(null);
        this.get('progr').setEmail(null);
        this.get('progr').setHCPPrograms(null);
        this.get('progr').setHCPProgramCount(0);
        this.get('progr').setHCPSites(null);
        this.get('progr').setPatient(null);
        this.get('progr').setSite(null);
      },

      loadStudy() {

        let selectedOption = this.get('selectedOption');
        if (!selectedOption && this.get('progr').getHCPProgramCount() === 1) {
          selectedOption = this.get('progr').getProgram(); //if HCP has only one program, default to that program
        }
        this.get('progr').setProgram(selectedOption);
        this.set('prog', selectedOption);

        if (this.get('progr').getRole() === "doctor" || this.get('progr').getRole() === "trainer") {
          let sites = this.get('progr').getHCPSites();
          this.get('progr').setSite(sites[selectedOption]);
          this.transitionToRoute('patientlog');
        } else if (this.get('progr').getRole() == 'recruiter') {
          this.get('progr').setPhysician(null); //reset selected hcp
          this.transitionToRoute('physicians');
        } else {
          this.get('progr').setPhysician(null); //reset selected hcp
          this.transitionToRoute('physiciantracking');
        }
      },

      login() {

        this.set('isLoadHCPProgramsDone', false); //initialize hcp study processing

        let { username, password } = this.getProperties('username', 'password');

        let credentials = {
          username: username,
          password: password
        };

        this.get('session').authenticate('authenticator:cognito', credentials).then(cognitoUserSession => {
          let cognitoUser = this.get('cognitoUser');
          if (!cognitoUser) return;

          cognitoUser.getUserAttributes();
          this.get('cognitoUser').getUserAttributes().then(userAttributes => {
            userAttributes.forEach(attr => {
              this.set(attr.getName(), attr.getValue());
            });
          });

          cognitoUser.getSession().then(session => {
            // It can happen in acceptance tests that 'session' is falsey
            if (session) {
              this.set('cognitoSession', session);
            }

            if (session) {
              let token = session.getIdToken();

              let jsontk = JSON.stringify(token, null, 2);

              var objectValue = JSON.parse(jsontk);

              var tokn = objectValue.jwtToken;
              this.get('progr').setToken(tokn);

              var groups = objectValue.payload['cognito:groups'];

              this.get('progr').setHCPPrograms(null);
              this.get('progr').setHCPProgramCount(0);
              this.get('progr').setHCPSites(null);
              this.get('progr').setRole('doctor');
              this.get('progr').setEmail(objectValue.payload["email"]);
              this.set('errorMessage', '');

              if (groups) {
                for (var i = 0; i < groups.length; i++) {
                  if (groups[i] == 'EffectusAdmins') {
                    this.get('progr').setRole('admin');
                    if (this.get('progr').get('pt_redirect')) {
                      this.transitionToRoute('patienttracking');
                    }
                  }
                  if (groups[i] == 'EffectusRecruiters') {
                    this.get('progr').setPatient(null); //only admins can redirect to patient
                    this.get('progr').set('pt_redirect', false); //only admins can redirect
                    this.get('progr').setProgram(null);
                    this.get('progr').setRole('recruiter');
                  }
                }
              } else {

                this.get('progr').setRole('doctor');
                this.get('progr').set('pt_redirect', false); //only admins can redirect
                this.get('progr').setPatient(null); //only admins can redirect to patient
                this.get('progr').setProgram(null);
                //this.get('progr').setName(username);
                var self = this;

                this.store.query('program', { phys_em: this.get('progr').getEmail() }).then(function (programs) {

                  //if physician has more than one study, multiple records will be returned
                  var study = programs.getEach('study_id');
                  var site = programs.getEach('site_id');

                  var phys = programs.getEach('physician_id');
                  var phys_fname = programs.getEach('physician_fname');
                  var phys_lname = programs.getEach('physician_lname');

                  var phys_name = phys_fname + ' ' + phys_lname;
                  if (phys_fname.length > 1) {
                    var phys_name = phys_fname[0] + ' ' + phys_lname[0]; //get value from first record
                  }

                  if (programs.getEach('for_training')[0]) {
                    self.get('progr').setRole('trainer');
                  }

                  self.get('progr').setPhysician(phys[0]);
                  self.get('progr').setName(phys_name);
                  self.get('progr').setHCPPrograms(study);
                  self.get('progr').setHCPProgramCount(study.length);
                  if (study.length === 1) {
                    //if only one study
                    self.get('progr').setProgram(study[0]); // if assigned to only one study
                    self.get('progr').setSite(site[0]);
                    let studysite = {};
                    studysite[study[0]] = site[0];
                    self.get('progr').setHCPSites(studysite);
                    self.transitionToRoute('patientlog');
                  } else if (study.length > 1) {
                    self.get('progr').setProgram(0); //temporary assignment o value of 0, must be number and not undefined
                    self.get('progr').setSite(0); //temporary assignment value of 0, must be number and not undefined                               
                    let studysite = {};
                    for (var i = 0; i < study.length; i++) {
                      let study_id = study[i];
                      studysite[study_id] = site[i];
                    }
                    self.get('progr').setHCPSites(studysite); //the study site for each study the hcp is part of
                  }
                  self.set('isLoadHCPProgramsDone', true);
                });
              }
            }
          });
        }).catch(error => {
          if (error) {
            if (error.name) {
              if (error.name === 'UserNotFoundException') {
                this.set('errorMessage', 'Username and/or password is not valid.');
              } else if (error.name === 'UserNotConfirmedException') {
                this.set('errorMessage', 'User account is unconfirmed. If the problem persists, contact support at engagesupport@effectusctr.com.');
              } else if (error.name === 'PasswordResetRequiredException') {
                this.set('errorMessage', 'Password reset is required. If a new window did not open, please click the "Forgot Password" link.');
                window.open(_environment.default.cognitoLinkForgotPassword + _environment.default.cognito.clientId, '_blank'); // confirm / verify
              } else {
                this.set('errorMessage', 'There was an error. Please contact support at engagesupport@effectusctr.com.');
              }
            } else if (error.state.name && error.state.name === 'newPasswordRequired') {
              this.set('errorMessage', 'Please update your temporary password to a permanent one. Sign in with your new permanent password.');
              window.open(_environment.default.cognitoLinkLogin + _environment.default.cognito.clientId, '_blank'); // login to AWS and then update password
            } else {
              this.set('errorMessage', error.state.name || error.message || error);
            }
          }
        });

        this.transitionToRoute('index');
      }
    }
  });
});