define('effectus-app/controllers/invoicetracking', ['exports', 'amazon-cognito-identity-js'], function (exports, _amazonCognitoIdentityJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    progr: Ember.inject.service('current-program'),

    actions: {
      switch() {
        this.transitionToRoute('invoicetrackingselect');
      }
    }
  });
});