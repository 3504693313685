define('effectus-app/helpers/is-equal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEqual = isEqual;
  function isEqual(params /*, hash*/) {
    var es = params[0];
    if (!es) es = '';

    return es === params[1];
  }

  exports.default = Ember.Helper.helper(isEqual);
});