define('effectus-app/components/index-auth', ['exports', 'effectus-app/templates/components/index-auth'], function (exports, _indexAuth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _indexAuth.default,
    currentUser: Ember.inject.service(),
    cognito: Ember.inject.service(),
    session: Ember.inject.service(),
    cognitoUser: Ember.computed.readOnly('cognito.user'),

    init() {
      this._super(...arguments);
      this.getSession();
    },

    getSession() {
      // Fetch the cognito session
      let cognitoUser = this.get('cognitoUser');
      if (cognitoUser) {
        return cognitoUser.getSession().then(session => {
          // It can happen in acceptance tests that 'session' is falsey
          if (session) {
            this.set('cognitoSession', session);
          }
        });
      }
    },

    tokenInfo(token) {
      return {
        expiration: new Date(token.getExpiration() * 1000),
        formatted: JSON.stringify(token.payload, undefined, 2)
      };
    },

    accessToken: Ember.computed('cognitoSession', function () {
      let session = this.get('cognitoSession');
      if (session) {
        return this.tokenInfo(session.getAccessToken());
      }
    }),

    idToken: Ember.computed('cognitoSession', function () {
      let session = this.get('cognitoSession');
      if (session) {
        return this.tokenInfo(session.getIdToken());
      }
    }),

    authenticatedData: Ember.computed('session.data', function () {
      return JSON.stringify(this.get('session.data'), undefined, 2);
    })
  });
});