define("effectus-app/controllers/patienttracking", ["exports", "effectus-app/utils/data-util"], function (exports, _dataUtil) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service("session"),
    progr: Ember.inject.service("current-program"),
    init: function () {
      this._super();
      this.set("isProcessing", false);
      this.set("questionSection", 0);
      this.set("editPatientID", 0); //the patient being edited
      this.set("currentQuestionKey", ""); //key for the question actively being answered (applies to study specific questions only)
      if (!this.get("dataUtil")) {
        this.set("dataUtil", _dataUtil.default.create());
      }
    },
    actions: {
      openNewPatientForm() {
        this.set("isAddingNewPatient", true);
        this.set("currentQuestionKey", "");
      },
      cancelNewPatientForm(patient) {
        this.set("isAddingNewPatient", false);
        this.set("isProcessing", false);
        this.set("errorMessage", "");
        this.set("currentQuestionKey", "");
        patient.rollbackAttributes();
      },
      editPatient(patient) {
        this.set("isEditingPatient", true);
        this.set("editModePatientID", patient.id);
        patient.set("editMode", true);
        patient.set("highlightPatient", "cell-selected");
      },
      cancelEditPatientForm(patient) {
        this.set("isEditingPatient", false);
        this.set("errorMessage", "");
        this.set("currentQuestionKey", "");
        patient.set("highlightPatient", "");
        patient.set("editMode", "");
        patient.rollbackAttributes();
      },
      setQuestionKey(key) {
        this.set("currentQuestionKey", key);
      },
      setPatientStatus(patient, value) {
        patient.set("enrollment_status", value);
      },
      setHCPScheduledAppt(patient, value) {
        patient.set("hcp_scheduled_appt", value);
      },
      setReasonDisqualified(patient, value) {
        patient.set("failed_disqualified_reason", value);
      },
      setState(patient, value) {
        patient.set("state", value);
      },
      setCountry(patient, value) {
        patient.set("country", value);
      },
      setAnswer(patient, value) {
        // study specific answers
        let question_key = this.get("currentQuestionKey");
        let answers = {};
        if (patient.get("patient_answers")) {
          answers = JSON.parse(this.get("dataUtil").escape(patient.get("patient_answers")));
        }
        answers[question_key] = value;
        patient.set("patient_answers", JSON.stringify(answers));
      },
      setAnswerDTDate(patient, value) {
        //study specific answer: set the date portion of the datetime
        let question_key = this.get("currentQuestionKey");
        let answers = {};
        if (patient.get("patient_answers")) {
          answers = JSON.parse(this.get("dataUtil").escape(patient.get("patient_answers")));
        }

        let new_date = new Date(value).toISOString().split("T")[0];
        let time = "00:00:00Z";
        let new_datetime = new_date + "T" + time;

        if (answers[question_key]) {
          //if answer value exists append new date to stored time
          let stored_datetime = answers[question_key].split("T"); //get the stored datetime
          time = stored_datetime[1]; //time portion
          new_datetime = new_date + "T" + time; //overwrite time portion with stored time
        }

        answers[question_key] = new_datetime;
        patient.set("patient_answers", JSON.stringify(answers));
      },
      setAnswerDTTime(patient, value) {
        //study specific answer: set the time portion of the datetime
        let question_key = this.get("currentQuestionKey");
        let answers = {};
        if (patient.get("patient_answers")) {
          answers = JSON.parse(patient.get("patient_answers"));
        }
        let new_time = "00:00:00Z"; //time portion
        if (value) {
          new_time = value + ":00Z"; //time portion
        }
        let date = "1900-01-01"; //temp date placeholder
        let new_datetime = date + "T" + new_time;

        if (answers[question_key]) {
          //if answer value exists append new date to stored time
          let stored_datetime = answers[question_key].split("T"); //get the stored datetime
          date = stored_datetime[0]; //date portion
          new_datetime = date + "T" + new_time;
        }

        answers[question_key] = new_datetime;
        patient.set("patient_answers", JSON.stringify(answers));
      },
      setAnswerCheckbox(patient, value) {
        //study specific answer
        let question_key = this.get("currentQuestionKey");
        let answers = {};
        if (patient.get("patient_answers")) {
          answers = JSON.parse(patient.get("patient_answers"));
        }
        let checked_answers = answers[question_key];

        let arr_answers = [];
        if (checked_answers) arr_answers = checked_answers.split(","); //

        //make comma delimited string of checked options
        if (arr_answers.includes(value)) {
          const index = arr_answers.indexOf(value);
          arr_answers.splice(index, 1);
        } else {
          arr_answers.push(value);
        }

        let new_checked_answers = arr_answers.join();
        answers[question_key] = new_checked_answers;
        patient.set("patient_answers", JSON.stringify(answers));
      },
      switch() {
        this.transitionToRoute("patienttrackingselect");
      }
    }
  });
});