define('effectus-app/routes/physicians/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    isValid: Ember.computed.match('physician.fname', /^.+@.+\..+$/),
    isDisabled: Ember.computed.not('isValid'),
    progr: Ember.inject.service('current-program'),
    beforeModel() {

      if (this.get('progr').getProgram() == null) this.transitionTo('index');

      if (this.get('progr').getRole() == 'trainer' || this.get('progr').getRole() == 'doctor') {
        this.transitionTo('patientlog');
      }

      if (this.get('progr').getRole() == null) {
        this.transitionTo('index');
      }
    },
    model() {
      return Ember.RSVP.hash({
        physician: this.store.createRecord('physician', { study_id: this.get('progr').getProgram() }),
        sitenum: this.store.query('sitenum', { study_id: this.get('progr').getProgram() }),
        specialty: this.store.findAll('specialty'),
        degree: this.store.findAll('degree'),
        program: this.store.query('program', { study_id: this.get('progr').getProgram() }),
        state: this.store.findAll('state'),
        phonetype: this.store.findAll('phonetype')
      });
    },
    setupController(controller, model) {
      controller.set('physician', model.physician);
      controller.set('sitenum', model.sitenum);
      controller.set('specialty', model.specialty);
      controller.set('degree', model.degree);
      controller.set('program', model.program);
      controller.set('state', model.state);
      controller.set('phonetype', model.phonetype);
    },
    actions: {
      setDegree(physician, selected) {
        physician.set('degree', selected);
      },
      setSpecial(physician, selected) {
        physician.set('speciality', selected);
      },
      setLicenseState(physician, selected) {
        physician.set('license_state', selected);
      },
      setLicenseState2(physician, selected) {
        physician.set('license_state2', selected);
      },
      setLicenseState3(physician, selected) {
        physician.set('license_state3', selected);
      },
      setPhoneTypePreferred(physician, selected) {
        physician.set('phone_preferred_type', selected);
      },
      setPhoneTypeAlternate(physician, selected) {
        physician.set('phone_alternate_type', selected);
      },
      setState(physician, selected) {
        physician.set('state', selected);
      },
      setSite(physician, selected) {
        physician.set('site_id', selected);
      },
      savePhysician(newPhysician) {
        newPhysician.set('audit_modifiedby', this.get('progr').getEmail());
        newPhysician.save().then(() => {
          this.controller.set('errorMessage', '');
          this.transitionTo('physiciantracking');
        }).catch(res => {
          this.controller.set('errorMessage', res.errors[0].detail);
        });
      },
      willTransition() {
        this.controller.set('errorMessage', '');
        // rollbackAttributes() removes the record from the store
        // if the model 'isNew'
        //console.log('willTransitioning');
        //this.controller.get('model').rollbackAttributes();
      }
    }
  });
});