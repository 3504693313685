define('effectus-app/models/patientlogquestion', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    study_id: _emberData.default.attr('number'),
    question_value: _emberData.default.attr('string'),
    question_key: _emberData.default.attr('string'),
    answer_type: _emberData.default.attr('string'),
    answer_values: _emberData.default.attr('string'),
    answer_keys: _emberData.default.attr('string'),
    answer_styles: _emberData.default.attr('string'),
    double_columns: _emberData.default.attr('number'),
    required: _emberData.default.attr('number'),

    arrKeys: Ember.computed('answer_type', function () {
      var keys = [];
      if (this.get('answer_keys')) {
        keys = this.get('answer_keys').split("|");
      }
      return keys;
    }),

    arrValues: Ember.computed('answer_values', function () {
      var values = [];
      if (this.get('answer_values')) {
        values = this.get('answer_values').split("|");
      }
      return values;
    }),

    arrStyles: Ember.computed('answer_styles', function () {
      var values = [];
      if (this.get('answer_styles')) {
        values = this.get('answer_styles').split("|");
      }
      return values;
    }),

    arrKeyedValues: Ember.computed('answer_type', function () {
      let values = [];
      let keys = [];
      let keyedValues = {};

      if (this.get('answer_keys')) {
        keys = this.get('answer_keys').split("|");
      }
      if (this.get('answer_values')) {
        values = this.get('answer_values').split("|");
      }

      if (keys && values) {
        let arrayLength = keys.length;
        for (var i = 0; i < arrayLength; i++) {
          let key = keys[i];
          let value = values[i];
          keyedValues[key] = value;
        }
      }

      return keyedValues;
    }),

    isBoolean: Ember.computed('answer_type', function () {
      if (this.get('answer_type') === 'BOOLEAN') return true;else return false;
    }),

    isRadio: Ember.computed('answer_type', function () {
      if (this.get('answer_type') === 'RADIO') return true;else return false;
    }),

    isCheck: Ember.computed('answer_type', function () {
      if (this.get('answer_type') === 'CHECKBOX') return true;else return false;
    }),

    isText: Ember.computed('answer_type', function () {
      if (this.get('answer_type') === 'TEXT') return true;else return false;
    }),

    isBigText: Ember.computed('answer_type', function () {
      if (this.get('answer_type') === 'BIGTEXT') return true;else return false;
    }),

    isDate: Ember.computed('answer_type', function () {
      if (this.get('answer_type') === 'DATE') return true;else return false;
    }),

    isNumeric: Ember.computed('answer_type', function () {
      if (this.get('answer_type') === 'NUMBER') return true;else return false;
    }),

    isDropdown: Ember.computed('answer_type', function () {
      if (this.get('answer_type') === 'DROPDOWN') return true;else return false;
    })
  });
});