define('effectus-app/models/physician', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    physician_number: _emberData.default.attr('string'),
    fname: _emberData.default.attr('string'),
    lname: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    institution: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    patient_caseload: _emberData.default.attr('string'),
    speciality: _emberData.default.attr('string'),
    degree: _emberData.default.attr('string'),
    phone_preferred: _emberData.default.attr('string'),
    email_preferred: _emberData.default.attr('string'),
    phone_alternate: _emberData.default.attr('string'),
    email_alternate: _emberData.default.attr('string'),
    phone_preferred_type: _emberData.default.attr('string'),
    phone_alternate_type: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),

    screening_notes: _emberData.default.attr('string'),
    date_screened: _emberData.default.attr('date'),

    zip: _emberData.default.attr('string'),
    npi_number: _emberData.default.attr('string'),
    license_number: _emberData.default.attr('string'),
    license_state: _emberData.default.attr('string'),

    license_number2: _emberData.default.attr('string'),
    license_state2: _emberData.default.attr('string'),

    license_number3: _emberData.default.attr('string'),
    license_state3: _emberData.default.attr('string'),

    site_id: _emberData.default.attr('number'),
    study_id: _emberData.default.attr('number'),
    audit_modifiedby: _emberData.default.attr('string')
  });
});