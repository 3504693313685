define('effectus-app/routes/contact/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    progr: Ember.inject.service('current-program'),

    model(params) {
      // return this.store.findRecord('physician', params.library_id);
      //model(params, queryParams) {

      // console.log(queryParams);
      //console.log(params);


      // console.log(queryParams.queryParams.pi);

      return Ember.RSVP.hash({
        contact: this.store.query('contact', { site_id: this.get('progr').getSite() }),
        program: this.store.query('program', { study_id: this.get('progr').getProgram() }),

        contact1: this.store.findRecord('contact1', params.library_id)
      });
    },
    setupController(controller, model) {
      //console.log('setup a controller');

      controller.set('contact', model.contact);
      controller.set('program', model.program);
      controller.set('contact1', model.contact1);

      //console.log(model.contact);
      //console.log(model.program);
      //console.log(model.contact1);

      //console.log('setup controller');
    },

    actions: {
      setSpecial(book, selected) {
        //console.log('set specialty');
        //console.log(selected);

        book.set('speciality', selected);
      },
      setState(book, selected) {
        //console.log('set state');
        //console.log(selected);

        book.set('state', selected);
      },
      setLicenseState(book, selected) {
        //console.log('set license state');
        //console.log(selected);

        book.set('license_state', selected);
      },
      setLicenseState2(book, selected) {
        //console.log('set license state 2');
        //console.log(selected);

        book.set('license_state2', selected);
      },
      setLicenseState3(book, selected) {
        //console.log('set license state 3');
        //console.log(selected);

        book.set('license_state3', selected);
      },
      setPhoneTypePreferred(book, selected) {
        //console.log('set phone type preferred');
        //console.log(selected);

        book.set('phone_preferred_type', selected);
      },
      setPhoneTypeAlternate(book, selected) {
        //console.log('set phone type alternate');
        //console.log(selected);

        book.set('phone_alternate_type', selected);
      },
      saveSiteContact(contact) {
        contact.set('audit_modifiedby', this.get('progr').getEmail());

        //patient.save().then(() => this.transitionTo('patienttracking'));
        contact.save().then(() => {
          this.controller.set('errorMessage', '');
          this.transitionTo('patienttracking');
        }).catch(res => {
          let errorMessage = res.errors[0].detail;
          this.controller.set('errorMessage', errorMessage);
        });
      },

      willTransition(transition) {

        let model = this.controller.get('contact1');

        if (model.get('hasDirtyAttributes')) {
          let confirmation = confirm("Your changes haven't saved yet. Would you like to leave this form?");

          if (confirmation) {
            model.rollbackAttributes();
          } else {
            transition.abort();
          }
        }
      }
    }
  });
});