define('effectus-app/helpers/fmt-num', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fmtNum = fmtNum;
  function fmtNum(params /*, hash*/) {
    if (params[0]) return params[0];else return '******';
  }

  exports.default = Ember.Helper.helper(fmtNum);
});