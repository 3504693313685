define('effectus-app/helpers/format-military-time', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatMilitaryTime = formatMilitaryTime;
  function formatMilitaryTime(params) {
    try {

      if (params && params[0].trim() != '') {
        // Append any date. Use your birthday.
        const new_time = new Date(params[0]).toLocaleTimeString({}, { timeZone: 'UTC', hour12: false, hour: 'numeric', minute: 'numeric' });

        return new_time;
      } else {
        return "";
      }
    } catch (e) {
      return "";
    }
  }

  exports.default = Ember.Helper.helper(formatMilitaryTime);
});