define('effectus-app/adapters/base', ['exports', 'ember-data', 'effectus-app/config/environment'], function (exports, _emberData, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	console.log('host', _environment.default.host);
	exports.default = _emberData.default.RESTAdapter.extend({

		session: Ember.inject.service('session'),
		progr: Ember.inject.service('current-program'),
		host: _environment.default.host,

		//urlForQuery (query, modelName) {
		urlForQuery() {
			return this._super(...arguments) + '?token_id=' + this.get('progr').getToken();
		},
		//urlForFindRecord (query, modelName) {
		urlForFindRecord() {
			return this._super(...arguments) + '?token_id=' + this.get('progr').getToken();
		},
		//urlForDeleteRecord (query, modelName) {
		urlForDeleteRecord() {
			return this._super(...arguments) + '?token_id=' + this.get('progr').getToken();
		},
		// urlForCreateRecord (query, modelName) {
		urlForCreateRecord() {
			return this._super(...arguments) + '?token_id=' + this.get('progr').getToken();
		},
		//urlForFindAll (query, modelName) {
		urlForFindAll() {
			return this._super(...arguments) + '?token_id=' + this.get('progr').getToken();
		},
		//urlForUpdateRecord (query, modelName) {
		urlForUpdateRecord() {
			return this._super(...arguments) + '?token_id=' + this.get('progr').getToken();
		},
		//urlForQueryRecord (query, modelName) {
		urlForQueryRecord() {
			return this._super(...arguments) + '?token_id=' + this.get('progr').getToken();
		}
	});
});