define('effectus-app/routes/about', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        session: Ember.inject.service('session'),
        progr: Ember.inject.service('current-program'),

        model(params) {
            return Ember.RSVP.hash({
                newWay: this.get('progr').getProgram()
            });
        },

        setupController(controller, model) {
            controller.set('newWay', model.newWay);
            //console.log("inside setup Application.js");
        }
    });
});