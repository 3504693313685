define('effectus-app/serializers/contact1', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.RESTSerializer.extend({
		normalizeResponse(store, primaryModelClass, payload, id, requestType) {
			payload = { contact1: payload };
			//	console.log(payload);
			return this._super(store, primaryModelClass, payload, id, requestType);
		},
		normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
			return this._super(store, primaryModelClass, payload, id, requestType);
		},
		normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
			return this._super(store, primaryModelClass, payload, id, requestType);
		},
		normalizeUpdateRecordResponse(store, primaryModelClass, payload, id, requestType) {
			return this._super(store, primaryModelClass, payload, id, requestType);
		}
	});
});