define('effectus-app/serializers/invoicetracking', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    /**
     * Invoice Status REST requests return as a simple JSON array, here we'll add the store name, as required by Ember to properly parse
     */
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      return this._super(store, primaryModelClass, { invoicetracking: payload }, id, requestType);
    }
  });
});