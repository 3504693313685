define('effectus-app/routes/invoicetracking', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    progr: Ember.inject.service('current-program'), // contains information about currently selected study, and logged in user info
    exportCsvService: Ember.inject.service('export-csv'),

    beforeModel() {

      if (!this.getPhysicianId()) {
        if (this.get('progr').getRole() == 'admin') {
          this.transitionTo('invoicetrackingselect');
        }
      }
      if (this.get('progr').getPhysician() == null) {
        this.transitionTo('invoicetrackingselect');
      }
      if (this.get('progr').getProgram() == null) {
        this.transitionTo('index');
      }
      if (this.get('progr').getRole() == 'trainer' || this.get('progr').getRole() == 'doctor') {
        this.transitionTo('physicians');
      }
      if (this.get('progr').getRole() == 'trainer') {
        this.transitionTo('patientlog');
      }
      if (this.get('progr').getRole() == null) {
        this.transitionTo('index');
      }
    },
    /**
     * Load model data needed to render page.
     * Ember will await the promises to complete, then call setupController with teh populated model
     */
    model() {
      let studyId = this.getStudyId();
      let physicianId = this.getPhysicianId();
      let userHasPermission = this.get('progr').get('personIsAdmin'); // this page is only available to admins
      return Ember.RSVP.hash({
        program: this.getProgramPromise(studyId, physicianId), // retrieve program info to be shown in page heading
        userHasPermission: userHasPermission,
        invoiceTrackings: userHasPermission && this.store.query('invoicetracking', { study_id: studyId, physician_id: physicianId }),
        invoiceTrackingsExport: userHasPermission && this.store.query('invoicetracking', { study_id: studyId }),
        physicians: userHasPermission && this.store.query('physicianid', { study_id: studyId }), // used to build physician selection dropdown
        draftInvoiceTrackings: [] // array to store newly created but not yet saved invoicetrackings
      });
    },

    actions: {
      /**
       * Add a new draft invoicetracking to the beginning of the invoicetrackings array so it shows as new editable item in UI
       */
      create() {
        let studyId = this.getStudyId();
        let physicianId = this.getPhysicianId();
        let draftInvoiceTracking = this.store.createRecord('invoicetracking', { studyId: studyId, physicianId: physicianId });
        draftInvoiceTracking.set('isNewInvoice', true);
        this.get('controller').get('model').draftInvoiceTrackings.unshiftObject(draftInvoiceTracking); // add draft new invoice tracking to beginning of list
      },

      cancel(invoiceTracking) {
        this.controller.set('errorMessage', '');
      },

      /**
       * Save the given invoiceTracking
       */
      save(invoiceTracking) {

        if (!invoiceTracking.get('physicianId')) return; // physicianId is required because there's a foreign key constraint on it

        invoiceTracking.set('auditModifiedby', this.get('progr').getEmail());
        //invoiceTracking.save().then(this.transitionTo('invoicetracking'));
        invoiceTracking.save().then(() => {
          invoiceTracking.set('highlightCell', '');
          this.controller.set('errorMessage', '');
          invoiceTracking.set('isNewInvoice', false);
          this.transitionTo('invoicetracking');
        }).catch(res => {
          let errorMessage = res.errors[0].detail;
          this.controller.set('errorMessage', errorMessage);
        });
      },

      /**
       * Delete the given InvoiceTracking record
       * @param invoiceTracking - model object to delete
       */
      delete(invoiceTracking) {
        let message = 'Confirm permanent removal of Invoice # ' + invoiceTracking.get('invoiceNumber') + '?';
        if (invoiceTracking.get('isNewInvoice')) {
          message = 'Confirm removal of this new Invoice?';
        }

        let isConfirmed = confirm(message);
        if (!isConfirmed) return;

        this.controller.set('errorMessage', '');

        // If record was created during this session, we need to remove it from the draft list
        this.get('controller').get('model').draftInvoiceTrackings.removeObject(invoiceTracking);
        invoiceTracking.destroyRecord(); // remove from database and in-browser model
      },

      /**
       * Export table as CSV file
       */
      exportCsv() {
        const config = {
          filename: 'invoice-tracking.csv',
          columns: [{ heading: "HCP Name", key: "physicianLastFirstName" }, { heading: "Invoice #", key: "invoiceNumber" }, { heading: "Invoice Date", key: "invoiceDate" }, { heading: "Services Start Date", key: "dateServicesStart" }, { heading: "Services End Date", key: "dateServicesEnd" }, { heading: "# Service Hours", key: "servicesHours" }, { heading: "Description of Services", key: "servicesDescription" }, { heading: "Hourly Rate", key: "hourlyRate" }, { heading: "Amount Owed", key: "amountOwed" }, { heading: "Payment Date", key: "datePaid" }, { heading: "Check #", key: "checkNumber" }, { heading: "Date Check Mailed", key: "dateCheckMailed" }, { heading: "Notes", key: "notes" }]
        };

        // Concatenate draft and existing items
        let data = [];
        this.get('controller').get('model').draftInvoiceTrackings.forEach(function (item) {
          data.push(item);
        });
        this.get('controller').get('model').invoiceTrackingsExport.forEach(function (item) {
          data.push(item);
        });

        this.get('exportCsvService').export(data, config);
      }
    },

    /**
     * Inject model elements into view controller.
     * Called after model() promises complete to pass data to templates
     */
    setupController(controller, model) {
      controller.set('model', model);
    },

    /**
     * Return active program information from the data store
     * @param studyId
     */
    getProgramPromise(studyId, physicianId) {
      return this.store.query('program', { study_id: studyId, physician_id: physicianId })
      // convert returned array into a single object, using queryRecord returns deprecation messages
      // See https://github.com/emberjs/data/issues/4255
      .then(function (result) {
        if (result.get('length') > 0) {
          return result.get('firstObject');
        }
      });
    },

    /**
     * Return currently selected studyid
     */
    getStudyId() {
      return this.get('progr').getProgram();
    },

    getPhysicianId() {
      return this.get('progr').getPhysician();
    }

  });
});