define('effectus-app/helpers/form-espo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formEspo = formEspo;
  function formEspo(params /*, hash*/) {
    // console.log(params[0]);

    // console.log(params[0].split('*||*'));
    var split = params[0].split('*||*');

    return split[1];
  }

  exports.default = Ember.Helper.helper(formEspo);
});