define('effectus-app/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    session: Ember.inject.service('session'),
    progr: Ember.inject.service('current-program'),

    model(params) {
      return Ember.RSVP.hash({
        newWay: this.get('progr').getProgram()
      });
    },

    setupController(controller, model) {
      controller.set('newWay', model.newWay);
      //console.log("inside setup Application.js");
    },

    actions: {

      setSelection: function (selected) {
        this.set('selectedOption', selected);
        //console.log(this.get('selectedOption'))
      },

      invalidateSession() {
        this.get('session').invalidate();
        this.get('progr').setToken(null);
        this.get('progr').setProgram(null);
        this.get('progr').setRole(null);
        this.get('progr').setPhysician(null);
        this.get('progr').setName(null);
        this.get('progr').setEmail(null);
        this.get('progr').setHCPPrograms(null);
        this.get('progr').setHCPSites(null);
        this.get('progr').setPatient(null);
        this.get('progr').setSite(null);
        //console.log('everything should be null');
        this.transitionToRoute('index');
      }
    }

  });
});