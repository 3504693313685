define("effectus-app/helpers/format-currency", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatCurrency = formatCurrency;
  function formatCurrency(params) {
    try {
      return `$${new Number(params[0]).toFixed(2)}`;
    } catch (e) {
      return "";
    }
  }

  exports.default = Ember.Helper.helper(formatCurrency);
});