define("effectus-app/helpers/format-comma-delimited-list", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatCommaDelimitedList = formatCommaDelimitedList;
  function formatCommaDelimitedList(param) {
    return param[0].replaceAll(param[1], param[2]);
  }

  exports.default = Ember.Helper.helper(formatCommaDelimitedList);
});