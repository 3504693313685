define("effectus-app/services/export-csv", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /**
     * Export the given data as a CSV browser download, based on the given config
     * @param data - ember array containing model objects to be exported
     * @param config - Export configuration, expected information:
     *          filename - download filename,
     *          columns - array of column configuration objects
     *            heading - display value for column header
     *            key - item.get(key) used to get raw value for cell
     */
    export(data, config) {
      if (!data) throw new Error("Export CSV Error: Missing data");
      if (!config) throw new Error("Export CSV Error: Missing config");
      if (!config.columns) throw new Error("Export CSV Error: Missing config.columns.");

      const headerRowCsv = headerRowCsvString(config);

      let csvString = headerRowCsv + "\n";

      data.forEach(function (item) {
        let rowCsvString = itemToCsvRowString(item, config);
        if (rowCsvString) {
          csvString += rowCsvString + "\n";
        }
      });

      initiateDownload(csvString, config);
    }
  });


  /**
   * Generate header row CSV string from config.column.heading elements
   */
  function headerRowCsvString(config) {
    const columnHeaderArray = config.columns.map(column => column.heading);
    const headerRowCsv = arrayToCsvRow(columnHeaderArray);
    return headerRowCsv;
  }

  /**
   * Extract data elements from ember item based on config columns and return CSV row string
   */
  function itemToCsvRowString(item, config) {
    let dataArray = [];
    for (let columnConfig of config.columns) {
      const value = item.get(columnConfig.key);
      dataArray.push(value);
    }
    return arrayToCsvRow(dataArray);
  }

  /**
   * Convert a 1-D array of values into a CSV row string
   */
  function arrayToCsvRow(dataArray) {
    if (!dataArray) return "";
    let csvRowString = dataArray.map(valueToCsvString).join(",");
    return csvRowString;
  }

  /**
   * Convert a string into a CSV-friendly string by escaping double-quotes and surrounding in quotes
   */
  function valueToCsvString(value) {
    let csvValue = value || "";

    if (csvValue.toLocaleDateString) {
      // if it's a date, format to local standard and ignore time
      //csvValue = csvValue.toLocaleDateString();
      csvValue = formatDate(csvValue.toISOString());
    }
    csvValue = "" + csvValue; // convert to string
    csvValue = csvValue.replace(/"/g, '""'); // escape double-quotes in string
    csvValue = csvValue.trim();
    if (csvValue) {
      csvValue = '"' + csvValue + '"'; // wrap string in quotes
    }
    return csvValue;
  }

  /**
   * Initiate browser download of CSV file
   * @param csvString - file contents
   * @param config - set config.filename to customize download save-as filename
   */
  function initiateDownload(csvString, config) {
    let downloadLink = document.createElement("a");
    downloadLink.download = config.filename || "export.csv"; // 'save-as' filename when download initiates
    /* downloadLink.href = window.URL.createObjectURL(
      new Blob(["\ufeff" + csvString], { type: "text/csv;charset=UTF-8" })
    );*/
    downloadLink.href = window.URL.createObjectURL(new Blob([csvString], { type: "text/csv;charset=UTF-8" }));
    downloadLink.style.display = "none"; // Hide download link
    document.body.appendChild(downloadLink); // Add the link to DOM
    downloadLink.click(); // Click download link
  }

  function formatDate(params) {
    try {
      //return new Date(params[0]).toLocaleDateString();
      if (params && params.trim() != "") {
        let date = params.split("T")[0];
        let date_parts = date.split("-");
        let year = date_parts[0];
        let month = date_parts[1];
        let day = date_parts[2];
        let date_formatted = month + "/" + day + "/" + year;
        return date_formatted;
      } else {
        return "";
      }
    } catch (e) {
      return "";
    }
  }
});