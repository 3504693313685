define('effectus-app/utils/data-util', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({

    init() {},

    escape(val) {
      if (typeof val != "string") return val;
      return val
      //.replace('\\',  '\\\\')
      .replace(new RegExp('\n', 'g'), '\\n').replace(new RegExp('\r', 'g'), '\\r').replace(new RegExp('\t', 'g'), '\\t').replace(new RegExp('\f', 'g'), '\\f').replace(new RegExp('\b', 'g'), '\\b').replace(new RegExp('"', 'g'), '\"');
    },

    /**
     * All values are string, and dates are stored like, "Wed Dec 12 2018 00:00:00 GMT-0500 (Eastern Standard Time)"
     * Attempt to parse them as dates and return them as a Date object. Otherwise return value as-is.
     */
    formatExportDate(value) {
      if (!value || value.length < 15) return value;
      let dateValue = new Date(value);
      if ("Invalid Date" == dateValue) return value;
      return dateValue;
    },
    formatExportDTDate(value) {
      if (value) {
        let str_date = new Date(value).toISOString();
        if (str_date.trim() != '') {
          let date = str_date.split('T')[0];
          let date_parts = date.split('-');
          let year = date_parts[0];
          let month = date_parts[1];
          let day = date_parts[2];
          let date_formatted = month + '/' + day + '/' + year;
          return date_formatted;
        }
      }
      return value;
    },
    formatExportDTTime(value) {
      if (value) {
        let time = new Date(value).toLocaleTimeString({}, { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' });
        return time;
      }
      return value;
    }

  });
});