define("effectus-app/components/patienttracking/patient-list", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      updatePatient(patient) {
        this.sendAction("updatePatient", patient);
      },
      deletePatient(patient) {
        this.sendAction("deletePatient", patient);
      },
      editPatient(patient) {
        if (patient.get("patientIsMinor")) {
          alert("PATIENT IS A MINOR");
        }
        this.sendAction("editPatient", patient);
      },
      cancelEditPatientForm(patient) {
        this.sendAction("cancelEditPatientForm", patient);
      },
      setQuestionKey(key) {
        this.sendAction("setQuestionKey", key);
      },
      setPatientStatus(patient, value) {
        this.sendAction("setPatientStatus", patient, value);
      },
      setHCPScheduledAppt(patient, value) {
        this.sendAction("setHCPScheduledAppt", patient, value);
      },
      setReasonDisqualified(patient, value) {
        this.sendAction("setReasonDisqualified", patient, value);
      },
      setState(patient, value) {
        this.sendAction("setState", patient, value);
      },
      setCountry(patient, value) {
        this.sendAction("setCountry", patient, value);
      },
      setAnswer(patient, value) {
        this.sendAction("setAnswer", patient, value);
      },
      setAnswerCheckbox(patient, value) {
        this.sendAction("setAnswerCheckbox", patient, value);
      },
      setAnswerDTDate(patient, value) {
        this.sendAction("setAnswerDTDate", patient, value);
      },
      setAnswerDTTime(patient, value) {
        this.sendAction("setAnswerDTTime", patient, value);
      },
      alertMinor(patient) {
        if (patient.get("patientIsMinor")) {
          alert("PATIENT IS A MINOR");
        }
      },
      listToggleOpen(id) {
        $("#list" + id).show();
      },
      listToggleClose(id) {
        $("#list" + id).hide();
      }
    }
  });
});