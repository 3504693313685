define('effectus-app/adapters/physicianinvoice', ['exports', 'effectus-app/adapters/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({

		pathForType() {
			return 'getPhysicianInvoices';
		},

		deleteRecord(store, type, snapshot) {
			var id = snapshot.id;
			var data = {};
			var serializer = store.serializerFor(type.modelName);

			serializer.serializeIntoHash(data, type, snapshot);

			return this.ajax(this.buildURL(type.modelName, id, snapshot, 'deleteRecord'), "DELETE", {
				data
			});
		}

	});
});