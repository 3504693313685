define('effectus-app/routes/invoicetrackingselect', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    session: Ember.inject.service('session'),
    progr: Ember.inject.service('current-program'),

    model(params) {
      return Ember.RSVP.hash({
        physicianids: this.store.query('physicianid', { study_id: this.get('progr').getProgram() })
      });
    },

    setupController(controller, model) {
      controller.set('ids', model.physicianids);
    },
    actions: {
      setSelection: function (selected) {
        this.set('selectedOption', selected);
      }
    }

  });
});