define('effectus-app/helpers/is-inside-reg', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isInsideReg = isInsideReg;
  function isInsideReg(params /*, hash*/) {
    // console.log('I am inside');
    // console.log('I am inside');
    // console.log(params[0]);
    //console.log(params[1]);
    // console.log('I am inside');
    //console.log(typeof(params[0]));
    //console.log(params[0]); 
    // console.log('I am inside');
    // console.log(params[1].split('*||*'));
    if (params[0]) {
      var split = params[0].split(',');

      //  console.log(split[1].includes(params[0]));

      return split.includes(params[1]);
    } else return false;
    // return true;
  }

  exports.default = Ember.Helper.helper(isInsideReg);
});