define('effectus-app/controllers/error', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        session: Ember.inject.service('session'),

        init: function () {
            this._super();
            this.get('session').invalidate();
            this.transitionToRoute('index');
        }
    });
});