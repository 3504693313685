define("effectus-app/router", ["exports", "effectus-app/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route("about");
    this.route("post");

    this.route("physiciantracking");

    this.route("patientlog");
    this.route("physicians", function () {
      this.route("new");
      this.route("assign");
      this.route("edit2", { path: "?pi=" });
      this.route("edit", { path: "/:library_id/edit" });
    });

    this.route("contact", function () {
      this.route("edit", { path: "/:library_id/edit" });
    });

    this.route("login");
    this.route("navbar");

    this.route("patienttracking");
    this.route("invoicetracking");
    this.route("patienttrackingselect");
    this.route("patientlogselect");
    this.route("invoicetrackingselect");
    this.route("physicianstudymap");
    this.route("invoiceupload");
    this.route("physicianinvoice");
    this.route("study");
    this.route("pinetwork");
    this.route("enlist");
  });

  exports.default = Router;
});