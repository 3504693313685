define("effectus-app/components/time-input", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    tagName: "input",
    type: "time",
    attributeBindings: ["name", "type", "value", "maxlength", "id"]
    /* keyDown: function(e) {
      var key = e.charCode || e.keyCode || 0;
      // allow enter, backspace, tab, delete, numbers, keypad numbers, home, end, :, AM, PM and space only
      return (
        key === 186 ||    // :
        key === 65 ||    // a
        key === 80 ||    // p
        key === 77 ||    // m
        key === 13 ||     //enter
        key === 8 ||      //backspace
        key === 9 ||      //tab
        key === 46 ||     //del
        key === 32 ||     //spacebar
        (key >= 35 && key <= 40) ||   //arrows, home, end
        (key >= 48 && key <= 57) ||   //0-9
        (key >= 96 && key <= 105));   //keypad 0-9
    },
    keyPress: function() {
      var inputValue = this.value || "";
      return (inputValue.length < this.maxlength);
    } */
  });
});