define('effectus-app/controllers/invoicetrackingselect', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    session: Ember.inject.service('session'),
    progr: Ember.inject.service('current-program'),

    init: function () {
      this._super();
      //console.log('screening controller init');
    },
    actions: {

      doSomethingOnClose(book) {

        //console.log('saving rr Date ');
        book.set('audit_modifiedby', this.get('progr').getEmail());
        book.save();
      },
      setSelection: function (selected) {
        this.set('selectedOption', selected);
        //console.log(this.get('selectedOption'))

        var split = this.get('selectedOption').split("%%%%");

        this.get('progr').setPhysician(split[0]);
        this.get('progr').setSite(split[1]);
        this.get('progr').setName(split[2]);

        //console.log(this.get(split[0]));
        //console.log(this.get(split[1]));
        //console.log(this.get(split[2]));

        this.transitionToRoute('invoicetracking');
      }

    }
  });
});