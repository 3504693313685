define('effectus-app/models/contact', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    site_id: _emberData.default.attr('number'),
    site_code: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),

    contact_name: _emberData.default.attr('string'),
    contact_email: _emberData.default.attr('string'),
    contact_phone: _emberData.default.attr('string'),
    contact_fax: _emberData.default.attr('string'),
    pi_name: _emberData.default.attr('string'),

    audit_modifiedby: _emberData.default.attr('string')
  });
});