define('effectus-app/models/physicianid', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    physician_id: _emberData.default.attr('number'),
    fname: _emberData.default.attr('string'),
    lname: _emberData.default.attr('string'),
    study_id: _emberData.default.attr('number'),
    site_id: _emberData.default.attr('number'),

    lastFirstName: Ember.computed('fname', 'lname', function () {
      return `${this.get('lname')}, ${this.get('fname')}`;
    })
  });
});