define("effectus-app/routes/pinetwork", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    progr: Ember.inject.service("current-program"),
    exportCsvService: Ember.inject.service("export-csv"),
    beforeModel() {
      if (this.get("progr").getRole() == "recruiter") {
        this.transitionTo("physicians");
      }
      if (this.get("progr").getRole() == "trainer" || this.get("progr").getRole() == "doctor") {
        this.transitionTo("patientlog");
      }
      if (this.get("progr").getRole() == null) {
        this.transitionTo("index");
      }
    },
    model() {
      return Ember.RSVP.hash({
        specialties: this.store.findAll("specialty"),
        piNetwork: this.store.findAll("pinetwork")
      });
    },
    setupController(controller, model) {
      controller.set("piNetwork", model.piNetwork);
      controller.set("piNetworkFiltered", model.piNetwork);
      controller.set("specialties", model.specialties);
    },
    actions: {
      saveNote(pi) {
        pi.set("displayMessage", "");
        pi.set("notes", pi.get("temp_notes"));
        pi.set("audit_modifiedby", this.get("progr").getEmail());
        pi.save().then(() => {
          pi.set("editMode", false);
          pi.set("displayMessage", "");
          pi.set("temp_notes", "");
        }).catch(res => {
          let errorMessage = res.errors[0].detail;
          pi.set("displayMessage", errorMessage);
        });
      },
      exportCsv() {
        const config = {
          filename: "pi-preferred-network.csv",
          columns: [{ heading: "Full Name", key: "full_name" }, {
            heading: "Currently working w/Effectus",
            key: "working_with_effectus"
          }, { heading: "Degree", key: "degree" }, { heading: "Specialty", key: "specialty" }, { heading: "Specialty Other", key: "specialty_other" }, { heading: "Email", key: "email" }, { heading: "Cell Phone", key: "phone_cell" }, { heading: "Office Phone", key: "phone_office" }, { heading: "Fax", key: "phone_fax" }, { heading: "Practice Name", key: "practice_name" }, { heading: "Practice Address", key: "practice_address" }, { heading: "Practice Address 2", key: "practice_address_2" }, { heading: "Practice City", key: "practice_city" }, { heading: "Practice State", key: "practice_state" }, { heading: "Practice Zip", key: "practice_zip" }, { heading: "Practice Country", key: "practice_country" }, { heading: "Practice Website", key: "practice_website" }, { heading: "Practice Setting Type", key: "practice_setting_type" }, { heading: "Practice Size", key: "practice_size" }, { heading: "Currently a PI or sub-I", key: "pi_or_subi" }, { heading: "Therapeutic Area(s)", key: "therapeutic_area" }, { heading: "Therapeutic Area Other", key: "therapeutic_area_other" }, {
            heading: "Years Experience Phase 1",
            key: "years_experience_phase_1"
          }, {
            heading: "Years Experience Phase 2",
            key: "years_experience_phase_2"
          }, {
            heading: "Years Experience Phase 3",
            key: "years_experience_phase_3"
          }, {
            heading: "Years Experience Phase 4",
            key: "years_experience_phase_4"
          }, {
            heading: "Trials Conducted Past 2 Years",
            key: "trials_conducted_2yrs"
          }, {
            heading: "Office Trials Currently Conducted",
            key: "office_trials_conducted"
          }, { heading: "IRB Type Used", key: "irb_type_used" }, {
            heading: "Have Documented Training On",
            key: "documented_training"
          }, { heading: "CV", key: "resume_cv" },
          /* { heading: "Date Received", key: "date_form_received" },*/
          { heading: "Notes", key: "notes" }]
        };

        let data = this.get("controller").get("piNetwork");
        this.get("exportCsvService").export(data, config);
      }
    }
  });
});