define('effectus-app/helpers/format-clean-label', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatCleanLabel = formatCleanLabel;
  function formatCleanLabel(params /*, hash*/) {
    let new_string = params[0].replace(/:$/, ''); // remove trailing semicolon when displaying
    return new_string;
  }

  exports.default = Ember.Helper.helper(formatCleanLabel);
});