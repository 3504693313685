define('effectus-app/services/current-program', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    progBill: null, //selected study for the session
    site: null,
    role: null,
    token: null,
    email: null,
    physician: null,
    name: null,
    hcp_progr: null, //list of studies that hcp/trainers are assigned to
    hcp_progr_count: 0, //total # programs hcp/trainer are assigned to
    hcp_sites: null, //list of study sites, by study, that hcp/trainer are assigned to
    patient: null,
    pt_redirect: false, //redirect to patient tracking, true only for admins that come to application from patient tracking email link click

    hcpProgramCount: Ember.computed('hcp_progr_count', function () {
      if (this.get('hcp_progr_count')) return this.get('hcp_progr_count');else return JSON.parse(localStorage.getItem('hcp_progr_count'));
    }),

    compBill: Ember.computed('role', function () {
      if (this.get('role')) return this.get('role');else return JSON.parse(localStorage.getItem('role'));
    }),

    compPhys: Ember.computed('name', function () {
      if (this.get('name')) return this.get('name');else return JSON.parse(localStorage.getItem('name'));
    }),

    compSite: Ember.computed('name', function () {
      if (this.get('site')) return this.get('site');else return JSON.parse(localStorage.getItem('site'));
    }),

    personIsAdmin: Ember.computed('role', function () {
      if (this.get('role')) {
        return this.get('role') === 'admin';
      } else {
        return JSON.parse(localStorage.getItem('role')) == 'admin';
      }
    }),

    personIsRecruiter: Ember.computed('role', function () {
      if (this.get('role')) {
        return this.get('role') === 'recruiter';
      } else {
        return JSON.parse(localStorage.getItem('role')) == 'recruiter';
      }
    }),

    personIsTrainer: Ember.computed('role', function () {
      if (this.get('role')) {
        return this.get('role') === 'trainer';
      } else {
        return JSON.parse(localStorage.getItem('role')) == 'trainer';
      }
    }),

    personIsDoctor: Ember.computed('role', function () {
      if (this.get('role')) {
        return this.get('role') === 'doctor';
      } else {
        return JSON.parse(localStorage.getItem('role')) == 'doctor';
      }
    }),

    hasAssignedProgram: Ember.computed('name', function () {
      if (this.isDoctor() === true) {
        if (this.get('progBill') !== undefined && this.get('progBill') !== null) return true;
        if (localStorage.getItem('progBill') !== "undefined" && localStorage.getItem('progBill') !== "null") return true;
        return false;
      }
      return true; //admins and recruiters have access to all programs
    }),

    doPTRedirect: Ember.computed('pt_redirect', function () {
      return this.get('pt_redirect');
    }),

    isDoctor() {
      if (this.get('role') && this.get('role') === 'doctor') return true;
      if (localStorage.getItem('role') !== "undefined" && localStorage.getItem('role') !== "null" && JSON.parse(localStorage.getItem('role')) === 'doctor') return true;
      return false;
    },

    setProgram(thing) {
      this.set('progBill', thing);
      localStorage.setItem('progBill', JSON.stringify(thing));
    },

    getProgram() {
      return JSON.parse(localStorage.getItem('progBill'));
    },

    setEmail(thing) {
      this.set('email', thing);
      localStorage.setItem('email', JSON.stringify(thing));
    },

    getEmail() {
      return JSON.parse(localStorage.getItem('email'));
    },

    setToken(thing) {
      this.set('token', thing);
      localStorage.setItem('token', JSON.stringify(thing));
    },

    getToken() {
      return JSON.parse(localStorage.getItem('token'));
    },

    setRole(thing) {
      this.set('role', thing);
      localStorage.setItem('role', JSON.stringify(thing));
    },

    getRole() {
      return JSON.parse(localStorage.getItem('role'));
    },

    setPhysician(thing) {
      this.set('physician', thing);
      localStorage.setItem('physician', JSON.stringify(thing));
    },

    getPhysician() {
      return JSON.parse(localStorage.getItem('physician'));
    },

    setPatient(thing) {
      this.set('patient', thing);
      localStorage.setItem('patient', JSON.stringify(thing));
    },

    getPatient() {
      return JSON.parse(localStorage.getItem('patient'));
    },

    setName(thing) {
      this.set('name', thing);
      localStorage.setItem('name', JSON.stringify(thing));
    },

    getName() {
      return JSON.parse(localStorage.getItem('name'));
    },

    setSite(thing) {
      this.set('site', thing);
      localStorage.setItem('site', JSON.stringify(thing));
    },

    getSite() {
      return JSON.parse(localStorage.getItem('site'));
    },

    getHCPPrograms() {
      return JSON.parse(localStorage.getItem('hcp_progr'));
    },

    setHCPPrograms(thing) {
      this.set('hcp_progr', thing);
      localStorage.setItem('hcp_progr', JSON.stringify(thing));
    },

    getHCPProgramCount() {
      return JSON.parse(localStorage.getItem('hcp_progr_count'));
    },

    setHCPProgramCount(thing) {
      this.set('hcp_progr_count', thing);
      localStorage.setItem('hcp_progr_count', JSON.stringify(thing));
    },

    getHCPSites() {
      return JSON.parse(localStorage.getItem('hcp_sites'));
      //return localStorage.getItem('hcp_sites');
    },

    setHCPSites(thing) {
      this.set('hcp_sites', thing);
      localStorage.setItem('hcp_sites', JSON.stringify(thing));
    }

  });
});