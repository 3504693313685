define("effectus-app/models/patienttracking", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    study_id: _emberData.default.attr("number"),
    study: _emberData.default.attr("string"),
    site_id: _emberData.default.attr("number"),
    site_code: _emberData.default.attr("string"),
    patient_tracking_id: _emberData.default.attr("number"),
    patient_deid_number: _emberData.default.attr("string"),
    physician_id: _emberData.default.attr("number"),
    physician_fname: _emberData.default.attr("string"),
    physician_lname: _emberData.default.attr("string"),

    patient_name: _emberData.default.attr("string"),
    guardian_name: _emberData.default.attr("string"),
    address: _emberData.default.attr("string"),
    address2: _emberData.default.attr("string"),
    city: _emberData.default.attr("string"),
    state: _emberData.default.attr("string"),
    zip: _emberData.default.attr("string"),
    country: _emberData.default.attr("string"),

    phone_preferred: _emberData.default.attr("string"),
    phone_alternate: _emberData.default.attr("string"),
    email_preferred: _emberData.default.attr("string"),

    hcp_scheduled_appt: _emberData.default.attr("string"),
    enrollment_status: _emberData.default.attr("string"),
    disqualified_reason_display_text: _emberData.default.attr("string"),
    failed_disqualified_reason: _emberData.default.attr("string"),
    failed_disqualified_reason_other: _emberData.default.attr("string"),

    date_signed_contactauth: _emberData.default.attr("date"),
    date_received_contactauth: _emberData.default.attr("date"),

    questions: _emberData.default.attr("string"), //list of questions and original answers, old code (see if can use new field patient_answers instead?)
    patient_answers: _emberData.default.attr(), // patient answers
    answers_required: _emberData.default.attr(""), //not used in patient tracking yet
    reify_site_id: _emberData.default.attr("string"),
    reify_trial_id: _emberData.default.attr("string"),
    sent_to_reify: _emberData.default.attr("boolean"),
    date_sent_to_reify: _emberData.default.attr("date"),
    audit_modified: _emberData.default.attr("date"),
    audit_modifiedby: _emberData.default.attr("string"),

    patient_first_name: Ember.computed("patient_name", function () {
      const patient_name = this.get("patient_name").trim();
      const arr_name = patient_name.split(" ");
      if (arr_name.length > 1) return arr_name[0];
      return patient_name;
    }),

    patient_last_name: Ember.computed("patient_name", function () {
      const patient_name = this.get("patient_name").trim();
      const arr_name = patient_name.split(" ");
      if (arr_name.length > 1) return arr_name[1];
      return patient_name;
    }),
    partner_referral_id: Ember.computed("study_id", function () {
      // used for Reify export
      return "S" + this.get("study_id") + "P" + this.get("id");
    }),
    patientIsMinor: Ember.computed("guardian_name", function () {
      let guardian_name = this.get("guardian_name");
      if (guardian_name && guardian_name.length > 0) return true;else return false;
    }),
    compQuestions: Ember.computed("questions", function () {
      return this.get("questions").split("----");
    })
  });
});