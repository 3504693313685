define("effectus-app/routes/login", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        redirect: function () {
            window.location.replace("https://auth.effectusctr.com/signup?redirect_uri=https://engage.effectusctr.com&response_type=token&client_id=4eachp99kubk5hlvthb0asje3i");
        }
    });
});