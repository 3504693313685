define("effectus-app/components/reason-disqualified", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "select",
    classNames: ["form-control"],
    authors: null,
    book: null,

    change(event) {
      const selectedId = event.target.value;
      this.sendAction("action", this.get("book"), selectedId);
    }
  });
});