define('effectus-app/helpers/lookup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    const lookupArray = params[0] || [];
    const key = params[1];
    const matchingItem = lookupArray.find(item => item.get('key') === key);
    if (!matchingItem) return;
    return matchingItem.get('value');
  });
});