define("effectus-app/models/enlist", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    fname: _emberData.default.attr("string"),
    lname: _emberData.default.attr("string"),
    email_preferred: _emberData.default.attr("string"),
    email_alternate: _emberData.default.attr("string"),
    degree: _emberData.default.attr("string"),
    specialty: _emberData.default.attr("string"),
    practice_type: _emberData.default.attr("string"),
    institution: _emberData.default.attr("string"),
    address: _emberData.default.attr("string"),
    address2: _emberData.default.attr("string"),
    city: _emberData.default.attr("string"),
    state: _emberData.default.attr("string"),
    zip: _emberData.default.attr("string"),
    phone1: _emberData.default.attr("string"),
    phone1_type: _emberData.default.attr("string"),
    phone2: _emberData.default.attr("string"),
    phone2_type: _emberData.default.attr("string"),
    fax: _emberData.default.attr("string"),
    npi: _emberData.default.attr("string"),
    sln: _emberData.default.attr("string"),
    opt_in_text: _emberData.default.attr("string"),
    opt_in_programs_survey: _emberData.default.attr("string"),
    opt_in_effectus_network: _emberData.default.attr("string"),
    pi_experience: _emberData.default.attr("string"),
    background_check_date: _emberData.default.attr("string"),
    background_check_status: _emberData.default.attr("string"),
    completed_consent_form: _emberData.default.attr("string"),
    completed_network_form: _emberData.default.attr("string"),
    opt_out: _emberData.default.attr("string"),
    programs: _emberData.default.attr("string"),
    notes: _emberData.default.attr("string"),
    source: _emberData.default.attr("string"),
    audit_modifiedby: _emberData.default.attr("string")
  });
});