define('effectus-app/helpers/is-equal-question', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEqualQuestion = isEqualQuestion;
  function isEqualQuestion(params /*, hash*/) {
    // console.log(params[0]);
    // console.log([params[1]]);

    // console.log(params[0].split('*||*'));
    var split = params[0].split('*||*');
    // console.log(typeof(params[0]));
    // console.log(typeof(params[1]));

    return split[0] === params[1];
  }

  exports.default = Ember.Helper.helper(isEqualQuestion);
});