define("effectus-app/models/physiciantracking", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    study_id: _emberData.default.attr("number"),
    site_id: _emberData.default.attr("number"),
    site_code: _emberData.default.attr("string"),
    address: _emberData.default.attr("string"),
    city: _emberData.default.attr("string"),
    state: _emberData.default.attr("string"),
    zip: _emberData.default.attr("string"),
    country: _emberData.default.attr("string"),
    pi_name: _emberData.default.attr("string"),
    contact_name: _emberData.default.attr("string"),
    contact_email: _emberData.default.attr("string"),
    contact_phone: _emberData.default.attr("string"),
    contact_fax: _emberData.default.attr("string"),
    physician_id: _emberData.default.attr("string"),
    physician_number: _emberData.default.attr("string"),
    willing: _emberData.default.attr("string"),
    reason_unwilling: _emberData.default.attr("string"),
    reason_unwilling_other: _emberData.default.attr("string"),
    screening_status: _emberData.default.attr("string"),
    screening_status_other: _emberData.default.attr("string"),
    screening_notes: _emberData.default.attr("string"),
    date_screened: _emberData.default.attr("date"),
    date_backgroundcheck_completed: _emberData.default.attr("date"),
    status_backgroundcheck: _emberData.default.attr("string"),
    date_contract_first_sent: _emberData.default.attr("date"),
    date_contract_resent: _emberData.default.attr("date"),
    date_contract_executed: _emberData.default.attr("date"),
    date_studysite_notified: _emberData.default.attr("date"),
    date_materials_shipped: _emberData.default.attr("date"),
    date_trained: _emberData.default.attr("date"),
    date_terminated: _emberData.default.attr("date"),
    tracking_notes: _emberData.default.attr("string"),
    recruitment_source: _emberData.default.attr("string"),
    fname: _emberData.default.attr("string"),
    lname: _emberData.default.attr("string"),
    institution: _emberData.default.attr("string"),
    paddress: _emberData.default.attr("string"),
    paddress2: _emberData.default.attr("string"),
    pcity: _emberData.default.attr("string"),
    pstate: _emberData.default.attr("string"),
    pzip: _emberData.default.attr("string"),
    pcountry: _emberData.default.attr("string"),
    email_preferred: _emberData.default.attr("string"),
    email_alternate: _emberData.default.attr("string"),
    phone_preferred: _emberData.default.attr("string"),
    phone_preferred_type: _emberData.default.attr("string"),
    phone_alternate: _emberData.default.attr("string"),
    phone_alternate_type: _emberData.default.attr("string"),
    fax: _emberData.default.attr("string"),
    patient_caseload: _emberData.default.attr("string"),
    speciality: _emberData.default.attr("string"),
    degree: _emberData.default.attr("string"),
    npi_number: _emberData.default.attr("string"),
    license_number: _emberData.default.attr("string"),
    license_number2: _emberData.default.attr("string"),
    license_number3: _emberData.default.attr("string"),
    license_state: _emberData.default.attr("string"),
    license_state2: _emberData.default.attr("string"),
    license_state3: _emberData.default.attr("string"),
    hcp_programs: _emberData.default.attr("string"),
    for_training: _emberData.default.attr("boolean"),
    audit_modified: _emberData.default.attr("string"),
    audit_modifiedby: _emberData.default.attr("string")
  });
});