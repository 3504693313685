define("effectus-app/routes/physiciantracking", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service("session"),
    cognito: Ember.inject.service("cognito"),
    cognitoUser: Ember.computed.readOnly("cognito.user"),
    needs: ["application"],
    progr: Ember.inject.service("current-program"),
    exportCsvService: Ember.inject.service("export-csv"),

    beforeModel() {
      if (this.get("progr").getProgram() == null) {
        this.transitionTo("index");
      }

      if (this.get("progr").getRole() == "recruiter") {
        this.transitionTo("physicians");
      }
      if (this.get("progr").getRole() == "trainer" || this.get("progr").getRole() == "doctor") {
        this.transitionTo("patientlog");
      }
      if (this.get("progr").getRole() == null) {
        this.transitionTo("index");
      }
    },

    model(params) {
      return Ember.RSVP.hash({
        physiciantracking: this.store.query("physiciantracking", {
          study_id: this.get("progr").getProgram()
        }),
        program: this.store.query("program", {
          study_id: this.get("progr").getProgram()
        }),
        sitenum: this.store.query("sitenum", {
          study_id: this.get("progr").getProgram()
        }),
        status: this.store.findAll("screeningstatus"),
        refusal: this.store.findAll("refusal")
      });
    },

    setupController(controller, model) {
      controller.set("param", model.param);
      controller.set("physiciantracking", model.physiciantracking);
      controller.set("program", model.program);
      controller.set("status", model.status);
      controller.set("refusal", model.refusal);
      controller.set("sitenum", model.sitenum);
    },

    actions: {
      willTransition: function (transition) {
        //if transitioning to a new page, reset
        let physiciantracking = this.controller.get("physiciantracking").findBy("physician_id", this.controller.get("editModePhysicianID"));
        if (physiciantracking) {
          physiciantracking.rollbackAttributes();
          physiciantracking.set("isEditing", false);
          physiciantracking.set("highlight", "");
        }
        this.controller.set("errorMessage", "");
        this.controller.set("editMode", false);
        this.controller.set("editModePhysicianID", "");
      },
      savePhysician(physiciantracking) {
        //this.controller.set('isProcessing', true); //show loading symbol
        physiciantracking.set("audit_modifiedby", this.get("progr").getEmail());
        let router = this;
        physiciantracking.save().then(() => {
          this.controller.set("errorMessage", "");
          this.controller.set("editMode", false);
          this.controller.set("editModePhysicianID", "");
          physiciantracking.set("isEditing", false);
          physiciantracking.set("highlight", "");
          //this.controller.set('isProcessing', false);
          this.refresh();
        }).catch(res => {
          //this.controller.set('isProcessing', false);
          this.controller.set("errorMessage", res.errors[0].detail);
        });
      },

      exportCsv() {
        const config = {
          filename: "hcp-screening-tracking.csv",
          columns: [{ heading: "HCP ID", key: "physician_id" }, { heading: "HCP First Name", key: "fname" }, { heading: "HCP Last Name", key: "lname" }, { heading: "HCP's Programs", key: "hcp_programs" }, { heading: "Study Site #", key: "site_code" }, { heading: "Study Site City", key: "city" }, { heading: "Study Site State", key: "state" }, { heading: "Study Site Country", key: "country" }, { heading: "Principal Investigator", key: "pi_name" }, { heading: "Screening Status", key: "screening_status" }, { heading: "Screening Status Other", key: "screening_status_other" }, { heading: "Institution", key: "institution" }, { heading: "Address", key: "paddress" }, { heading: "Address 2", key: "paddress2" }, { heading: "City", key: "pcity" }, { heading: "State", key: "pstate" }, { heading: "Zip", key: "pzip" }, { heading: "Preferred Email", key: "email_preferred" }, { heading: "Alternate Email", key: "email_alternate" }, { heading: "Preferred Phone", key: "phone_preferred" }, { heading: "Preferred Phone Type", key: "phone_preferred_type" }, { heading: "Alternate Phone", key: "phone_alternate" }, { heading: "Alternate Phone Type", key: "phone_alternate_type" }, { heading: "Fax Number", key: "fax" }, { heading: "Specialty", key: "speciality" }, { heading: "Degree", key: "degree" }, { heading: "Patient Caseload", key: "patient_caseload" }, { heading: "Screening Notes", key: "screening_notes" }, { heading: "NPI", key: "npi_number" }, { heading: "License 1", key: "license_number" }, { heading: "License State", key: "license_state" }, { heading: "License 2", key: "license_number2" }, { heading: "License State 2", key: "license_state2" }, { heading: "License 3", key: "license_number3" }, { heading: "License State 3", key: "license_state3" }, { heading: "Date Screened", key: "date_screened" }, {
            heading: "Date Background Check Completed",
            key: "date_backgroundcheck_completed"
          }, { heading: "Background Check Status", key: "status_backgroundcheck" }, {
            heading: "Date Contract First Sent",
            key: "date_contract_first_sent"
          }, { heading: "Date Contract Resent", key: "date_contract_resent" }, { heading: "Date Contract Executed", key: "date_contract_executed" }, {
            heading: "Date Study Site Notified",
            key: "date_studysite_notified"
          }, { heading: "Date Materials Shipped", key: "date_materials_shipped" }, { heading: "Date HCP Trained", key: "date_trained" }, { heading: "Date HCP Terminated", key: "date_terminated" }, { heading: "Tracking Notes", key: "tracking_notes" }, { heading: "Recruitment Source", key: "recruitment_source" }]
        };

        let data = this.get("controller").get("physiciantracking");
        this.get("exportCsvService").export(data, config);
      }
    }
  });
});