define('effectus-app/controllers/study', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    session: Ember.inject.service('session'),
    progr: Ember.inject.service('current-program'),

    init: function () {
      this.set('errorMessage', '');
    },
    actions: {
      deactivateProgram(study) {
        if (confirm("Deactivating the program will disable all HCPs from accessing this study. Are you sure you want to proceed?")) {
          study.set('is_active', false);
          this.send('updateStudy', study);
        }
      },
      activateProgram(study) {
        if (confirm("Activating the program will enable access for ACTIVE HCPs to this study. Are you sure you want to proceed?")) {
          study.set('is_active', true);
          this.send('updateStudy', study);
        }
      }
    }
  });
});