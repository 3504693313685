define('effectus-app/helpers/is-inside', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isInside = isInside;
  function isInside(params /*, hash*/) {
    // console.log('I am inside');
    // console.log('I am inside');
    //console.log(params[0]);
    //console.log(params[1]);
    // console.log('I am inside');
    // console.log('I am inside');
    // console.log(params[1].split('*||*'));

    var split = params[1].split('*||*');

    //  console.log(typeof(split));
    //  console.log(typeof(params[0]));

    //  console.log(split[1].includes(params[0]));

    return split[1].includes(params[0]);
    // return true;
  }

  exports.default = Ember.Helper.helper(isInside);
});